import { cloneDeep, merge } from "lodash";
import {
  DEFAULT_FAIL_MESSAGE_AFTER,
  PartnerSetting,
  staticPageDefaultContent,
} from "./defaults";

const SETTINGS_988: PartnerSetting = {
  misc: {
    keyword: "988",
    phoneNumber: "988",
  },
  timeouts: {
    timeoutReminder: 5 * 60,
    timeToTimeout: 10 * 60,
    failMessageAfter: DEFAULT_FAIL_MESSAGE_AFTER,
    timeToClearConversationAfterClose: 10, // change to 600
    clearConversationAfterClose: false,
  },
  disconnectDisclaimer: "",
  startMessage: {
    showStartMessageText: true,
    startMessageText:
      "Thank you for your patience. There may be a wait time to connect with a counselor. To speak to a counselor now, please call the Lifeline at <a href='tel:+988'>988</a>. You can also visit a <a href='https://safespace.vibrant.org/en/'>Safe Space</a> while you wait.",
    startMessageTitle: "Welcome.",
    showTime: "inline",
  },
  stopButtonVariant: "simple",
  idleMessageStyle: "inline",

  autoStartConversation: true,
  showMenu: false,
  tracking: {
    trackWindowClosed: true,
  },
  ui: {
    stopButtonText: {
      simple: "End Chat",
      complex: "Need to Leave?",
    },
    cancelButtonText: "Cancel",
    okButtonText: "Ok",
    chatboxLabelText: "Type your message here",
    chatboxTooShortPreText:
      "Your message is too short. Please increase to at least",
    chatboxTooShortPostText: "characters to send.",
    chatboxTooLongPreText: "Your message is too long. Please limit to",
    chatboxTooLongPostText: "characters to send.",
    closeConvoPreText:
      "You've left the chat. For immediate assistance, please call",
    closeConvoPostText: "or refresh the page to rejoin the queue.",
    sendMessageButtonText: "Send",
    sendMessageButtonColor: "secondary",
    closeConversationDialog: {
      leaveChat: "If you need to leave, message your Crisis Counselor.",
      leaveChatCompact: "Are you sure you wish to end this chat session?",
    },
    yourConversationHasBeenClosed: "Your conversation has been closed.",
  },
  staticPages: {
    generic: {
      description: staticPageDefaultContent.generic.description,
    },
    noToken: {
      description:
        "Thank you for reaching out. Lifeline chat is currently unavailable. For support, now we encourage you to call 988.",
    },
    disconnected: {
      description: staticPageDefaultContent.disconnected.description,
    },
    cannotConnect: {
      description:
        "Thank you for reaching out. Lifeline chat is currently unavailable. For support, now we encourage you to call 988.",
    },
    openedInAnotherTab: {
      description: staticPageDefaultContent.openedInAnotherTab.description,
    },
    conversationCleared: {
      description: staticPageDefaultContent.conversationCleared.description,
    },
    notFound: {
      description: staticPageDefaultContent.notFound.description,
    },
  },
};

export const SETTINGS_988_ES: PartnerSetting = merge(cloneDeep(SETTINGS_988), {
  startMessage: {
    startMessageText:
      "Gracias por su paciencia. Puede haber un tiempo de espera para conectar con un Consejero de Crisis. Para hablar con un Consejero de Crisis ahora, por favor llame al Lifeline usando el número <a href='tel:+988'>988</a>.",
    startMessageTitle: "Bienvenido.",
  },
  ui: {
    stopButtonText: {
      simple: "Finalizar Conversación",
      complex: "¿Quieres salir?",
    },
    cancelButtonText: "Cancelar",
    chatboxLabelText: "Escribe tu mensaje aquí",
    chatboxTooShortPreText: "Tu mensaje es demasiado corto. Añade al menos",
    chatboxTooShortPostText: "caracteres y envía de nuevo",
    chatboxTooLongPreText: "Tu mensaje es demasiado largo. Limita tu mensaje a",
    chatboxTooLongPostText: "caracteres",
    closeConvoPreText:
      "Ha salido de la conversación. Para asistencia inmediata, por favor llame al número",
    closeConvoPostText:
      "o refresque la página para entrar de nuevo en la fila.",
    sendMessageButtonText: "Enviar",
    closeConversationDialog: {
      leaveChat:
        "Si necesitas salir, envía un mensaje a tu Consejero de Crisis",
      leaveChatCompact: "¿Seguro que desea finalizar esta conversación?",
    },
    yourConversationHasBeenClosed: "Tu conversación ha sido finalizada.",
  },
  staticPages: {
    generic: {
      description:
        "Incluya una descripción del error y acción que el usuario debe realizar para resolverlo (o método alternativo).",
    },
    noToken: {
      description:
        "Gracias por acceder al servicio. Lifeline chat no está disponible en este momento. Le invitamos que llame a 988 si necesita asistencia inmediata.",
    },
    disconnected: {
      description: "Ha sido desconectado. Obtenga otro enlace.",
    },
    cannotConnect: {
      description:
        "Gracias por acceder al servicio. Lifeline chat no está disponible en este momento. Le invitamos que llame a 988 si necesita asistencia inmediata.",
    },
    openedInAnotherTab: {
      description:
        "¿Quieres hablar aquí? \n" +
        "Recarga la página para continuar la conversación.",
    },
    conversationCleared: {
      description:
        "Ha sido desconectado y la conversación ha sido borrada. Obtenga otro enlace.",
    },
    notFound: {
      description: "No hemos podido encontrar lo que busca.",
    },
  },
});

export default SETTINGS_988;
