import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { List } from "@material-ui/core";
import Message from "./Message";
import { selectMessages } from "app/redux/selectors/conversationSelector";
import useStyles from "./styles";
import { usePrevious } from "app/utils/reduxEffects";
import { usePageVisibility } from "react-page-visibility";
import useChangeTitleFlashing from "app/utils/hooks/useChangeTitleFlashing";
import SystemMessage from "./SystemMessage";

let pageTitle = document.title;
export default function Conversation() {
  const classes = useStyles();
  const messages = useSelector(selectMessages);
  const lastItemInList = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const prevMessages = usePrevious<typeof messages>(messages);
  const isPageVisible = usePageVisibility();
  const [missedMessages, setMissedMessages] = useState<number>(0);
  const scrollToLastElement = (smooth = true) => {
    listRef.current?.scrollTo({
      behavior: smooth ? "smooth" : undefined,
      top: lastItemInList.current?.offsetTop,
    });
  };

  useEffect(() => {
    pageTitle = document.title;
  }, []);

  useEffect(() => {
    // check that the length had changed.
    const newMessagesCount =
      Object.values(messages).length - Object.values(prevMessages).length;
    if (newMessagesCount > 0) {
      scrollToLastElement(!!isPageVisible);
    }
    if (isPageVisible) {
      setMissedMessages(0);
    } else {
      setMissedMessages(missedMessages + newMessagesCount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isPageVisible]);

  useChangeTitleFlashing({
    shouldChangeTitle: !isPageVisible && missedMessages > 0,
    title: `(${missedMessages}) ${pageTitle}`,
    favicon: "notification",
  });

  return (
    <List className={classes.thread} ref={listRef} data-testid="conversation">
      <div className={classes.toolbarMargin} />
      {Object.values(messages).map((message) => {
        const { systemGenerated, messageId, systemMessage } = message;
        if (systemGenerated) {
          return "";
        }
        if (systemMessage) {
          return (
            <SystemMessage
              title={message.systemMessageTitle || ""}
              message={message.body}
              sendAt={message.sendAt}
              key={messageId}
              color={message.systemMessageColor || "blue"}
            />
          );
        }
        return <Message {...message} key={messageId} />;
      })}
      <div ref={lastItemInList} />
    </List>
  );
}
