import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { createRootReducer } from "app/redux/slices/rootReducer";
import rootSaga from "app/redux/sagas";
import createSagaMiddleware from "redux-saga";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import type { History } from "history";

// we need to create app store that way to share the code with tests
// we should have the same history instance passed to router
export const createAppStore = (history: History) => {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({ history: history });

  const sagaMiddleware = createSagaMiddleware();
  const { run: runSaga } = sagaMiddleware;
  const store = configureStore({
    reducer: createRootReducer(routerReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(sagaMiddleware, routerMiddleware),
    devTools: process.env.NODE_ENV !== "production",
  });
  runSaga(rootSaga);
  return { store, createReduxHistory };
};

const browserHistory = createBrowserHistory();
const { store, createReduxHistory } = createAppStore(browserHistory);

const history = createReduxHistory(store);
const persistor = persistStore(store);
export { store, persistor, history };

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("app/redux/slices/rootReducer", () => {
    const newRootReducer = require("app/redux/slices/rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
