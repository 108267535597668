import { ListItem } from "@material-ui/core";
import useStyles from "./styles";
import type { Message as MessageType } from "app/redux/slices/conversationSlice";
import Linkify from "react-linkify";
import { selectIsIframeUI } from "app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";
import MessageInfo from "./MessageInfo";
import Analytics from "app/utils/analytics";
import { events } from "../../app/utils/analytics/events";

export default function Message(message: MessageType) {
  const { body, isMine } = message;
  const isIframeUI = useSelector(selectIsIframeUI);
  const classes = useStyles();

  const getMessageClassName = () => {
    if (isMine) {
      return isIframeUI ? classes.myMessage988 : classes.myMessage;
    }
    return isIframeUI ? classes.theirMessage988 : classes.theirMessage;
  };
  return (
    <ListItem style={{ flexDirection: "column" }}>
      <div
        className={getMessageClassName()}
        aria-live="polite"
        data-testid={`message-${message.messageId}`}
      >
        <Linkify
          componentDecorator={(href, text, key) => (
            <a
              href={href}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                Analytics.log(events.userActions.clickLink, { href: href });
              }}
            >
              {text}
            </a>
          )}
        >
          {body}
        </Linkify>
      </div>
      <div className={isMine ? classes.myTime : classes.theirTime}>
        <MessageInfo {...message} />
      </div>
    </ListItem>
  );
}
