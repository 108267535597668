import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import localStorage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import AuthTransform from "./transforms/authTransform";

export const PARTNER_988 = "988" as const;
export const PARTNER_GOOGLE = "CTL" as const;
export const PARTNER_CTL_WEBCHAT = "CTL_WEBCHAT" as const;

export type Partner =
  | typeof PARTNER_988
  | typeof PARTNER_GOOGLE
  | typeof PARTNER_CTL_WEBCHAT;

export type LanguageCode = "en" | "es";

export type User = {
  identity: string;
  partner: Partner;
  iframe: boolean;
  lang: LanguageCode;
};

type SliceState = {
  user: User;
};

const initialState: SliceState = {
  user: {
    identity: "",
    partner: PARTNER_GOOGLE,
    iframe: false,
    lang: "en",
  },
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUserAuth: (state, action: PayloadAction<User>) => {
      const { identity, partner, iframe, lang } = action.payload;
      state.user.identity = identity;
      state.user.partner = partner;
      state.user.iframe = iframe;
      state.user.lang = lang;
    },
  },
});

export const { addUserAuth } = AuthSlice.actions;

const authReducer = persistReducer(
  {
    key: "auth",
    storage: localStorage,
    whitelist: ["user"],
    version: 3,
    // when the state is rehydrated and partner key is missing it's not going to merge with the initial state as the
    // data exist in level 2  under user, so we are using a transformer to fill partner if it's missing.
    transforms: [AuthTransform],
  },
  AuthSlice.reducer
);

export default authReducer;
