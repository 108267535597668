import { FormControl, TextField, Typography } from "@material-ui/core";
import { ChangeEvent } from "react";
import useStyles from "./styles";

type TextComposerProps = {
  onSendMessage: () => void;
  onTextFieldChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  error: boolean;
  helperText: string;
  maxLength: number;
  message: string;
};

const TextComposer = ({
  onSendMessage,
  onTextFieldChange,
  label,
  error,
  helperText,
  maxLength,
  message,
}: TextComposerProps) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.composeContainer}
    >
      <TextField
        id="compose-your-message"
        aria-label={label}
        label={label}
        title={label}
        error={error}
        variant="outlined"
        multiline
        maxRows={4}
        className={classes.compose}
        onChange={onTextFieldChange}
        value={message}
        helperText={helperText}
        aria-valuemax={maxLength}
        onKeyPress={({ key }) => key === "Enter" && onSendMessage()}
        aria-describedby="compose-helper-text"
      />
      <Typography id="compose-helper-text" variant="srOnly">
        Use this box to compose your message
      </Typography>
    </FormControl>
  );
};

export default TextComposer;
