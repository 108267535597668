import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import StaticPage from "./pages/staticPage";
import ErrorContainer from "components/Errors/ErrorContainer";
import Auth from "./pages/Auth";
import Partner from "./pages/Partner";
import AppNamedRoutes from "./AppNamedRoutes";

export default function AppRouter() {
  return (
    <Routes>
      <Route path={AppNamedRoutes.home} element={<Home />} />
      <Route path={AppNamedRoutes.partner} element={<Partner />} />
      <Route path={AppNamedRoutes.chat} element={<Partner />} />
      <Route
        path={AppNamedRoutes.noToken}
        element={
          <StaticPage>
            <ErrorContainer error="noToken" />
          </StaticPage>
        }
      />
      <Route
        path={AppNamedRoutes.disconnected}
        element={
          <StaticPage>
            <ErrorContainer error="disconnected" />
          </StaticPage>
        }
      />
      <Route
        path={AppNamedRoutes.linkExpired}
        element={
          <StaticPage>
            <ErrorContainer error="cannotConnect" />
          </StaticPage>
        }
      />

      <Route path={AppNamedRoutes.auth} element={<Auth />} />

      {process.env.REACT_APP_988_AND_GOOGLE_CLASSROOM_ARE_ENABLED !==
        undefined &&
        process.env.REACT_APP_988_AND_GOOGLE_CLASSROOM_ARE_ENABLED.toLowerCase() ===
          "true" && (
          <>
            <Route
              path={AppNamedRoutes.auth988}
              element={<Auth useIframeWorkflow />}
            />

            <Route
              path={AppNamedRoutes.iframe}
              element={<Auth useIframeWorkflow />}
            />
          </>
        )}
      <Route
        path="/asd"
        element={
          <StaticPage>
            <ErrorContainer error="generic" />
          </StaticPage>
        }
      />

      <Route
        path="*"
        element={
          <StaticPage>
            <ErrorContainer error="notFound" />
          </StaticPage>
        }
      />
    </Routes>
  );
}
