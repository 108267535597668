import type {
  IncomingSocketEventsPlain,
  SocketEventDataMap,
} from "app/sharedTypes/socketActionTypes";
import { createAction } from "@reduxjs/toolkit";
import type { User } from "app/redux/slices/authSlice";

type OnClosePayload = {
  code: number;
  reason: string;
  wasClean: boolean;
};

type OnOpenPayload = {
  reconnecting: boolean;
};
type DoConnectPayload = {
  user: User;
  reconnecting?: boolean;
};

export const doConnect = createAction<DoConnectPayload>("doConnect");
export const closeConnection =
  createAction<SocketEventDataMap["closeConnection"]>("closeConnection");
export const updateConversationStatus = createAction<
  SocketEventDataMap["updateConversationStatus"]
>("updateConversationStatus");
export const onOpen = createAction<OnOpenPayload>("onOpen");
export const onClose = createAction<OnClosePayload>("onClose");
export const onError = createAction("onError");
export const onMessage = createAction<IncomingSocketEventsPlain>("onMessage");
