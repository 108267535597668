import { createAction } from "@reduxjs/toolkit";
import type { OutgoingSocketEventsPlain } from "app/sharedTypes/socketActionTypes";
import { Message } from "app/redux/slices/conversationSlice";
import { Sound } from "app/utils/sound";

type GeneralMessage = {
  message: string;
  systemGenerated?: boolean;
};

type DelayFailMessage = {
  messageId: string;
  action: "cancel" | "add";
};

export const emitSocketAction =
  createAction<OutgoingSocketEventsPlain>("emitSocketAction");
export const sendMessage = createAction<GeneralMessage>("sendMessage");
export const retrySendMessage = createAction<Message>("retrySendMessage");
export const delayFailMessage =
  createAction<DelayFailMessage>("delayFailMessage");
export const playSoundAction = createAction<Sound>("playSound");
