import { useEffect, useState } from "react";
import Splash from "components/Splash";
import useQuery from "../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { getIdentity } from "app/utils/AuthRemote";
import { addUserAuth, Partner } from "app/redux/slices/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import AppNamedRoutes from "AppNamedRoutes";
import {
  selectPartnerSettings,
  selectUser,
} from "app/redux/selectors/conversationSelector";
import useInitConversation from "app/utils/hooks/useInitConversation";
import { useSendInitialMessage } from "app/utils/hooks/useSendMessage";

const LANG = "locale";

type AuthProps = {
  useIframeWorkflow?: boolean;
};

function Auth({ useIframeWorkflow = false }: AuthProps) {
  const query = useQuery();
  const params = useParams();
  const token = params.token || "";
  const lang = query.get(LANG) === "es" ? "es" : "en";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const initConversation = useInitConversation();
  const sendInitialMessage = useSendInitialMessage();
  const setting = useSelector(selectPartnerSettings);
  const [mainAuthFlowRun, setMainAuthFlowRun] = useState(false);

  useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const requestPromise = getIdentity(token, signal);
      requestPromise
        .then(async (response) => {
          // first check if a user is already logged in on a none iframe version
          // we will need to reuse there identity
          const isSamePartner = user.partner === response.provider;

          const avoidReauthorize =
            user.identity &&
            !useIframeWorkflow &&
            isSamePartner &&
            user.iframe === useIframeWorkflow;

          if (!avoidReauthorize) {
            dispatch(
              addUserAuth({
                identity: response.identity,
                // make sure the partner name is a capital string as we are using it as capital
                partner: response.provider.toUpperCase() as Partner,
                iframe: useIframeWorkflow,
                lang: lang,
              })
            );
          }
          setMainAuthFlowRun(true);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("successfully aborted");
          } else {
            return navigate(AppNamedRoutes.linkExpired);
          }
        });
      return () => {
        controller.abort();
      };
    },
    //TODO: try to remove this but so far it's needed because the component re-renders two times and sends the intial message 2 times
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // once authenticated
  useEffect(() => {
    if (mainAuthFlowRun && user.identity) {
      if (setting.autoStartConversation) {
        initConversation();
        sendInitialMessage();
      }

      navigate(AppNamedRoutes.home);
    }
  }, [
    mainAuthFlowRun,
    user.identity,
    setting,
    initConversation,
    sendInitialMessage,
    navigate,
  ]);

  return (
    <div className="App" style={{ textAlign: "center" }}>
      <Splash showCTLLogo={!useIframeWorkflow} />
    </div>
  );
}

export default Auth;
