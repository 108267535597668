import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { secToMs } from "../converter";
import { clearConversation } from "app/redux/slices/conversationSlice";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";

const useClearConversation = () => {
  const dispatch = useDispatch();
  const setting = useSelector(selectPartnerSettings);
  useEffect(() => {
    if (!setting.timeouts.clearConversationAfterClose) {
      return;
    }
    const reminderTimer = window.setTimeout(() => {
      dispatch(clearConversation());
    }, secToMs(setting.timeouts.timeToClearConversationAfterClose));
    return () => {
      clearTimeout(reminderTimer);
    };
  }, [
    dispatch,
    setting.timeouts.timeToClearConversationAfterClose,
    setting.timeouts.clearConversationAfterClose,
  ]);
};

export default useClearConversation;
