import { Grid, Typography } from "@material-ui/core";
import MainContainer from "components/Layout/MainContainer";
import { useAnalyticsLog } from "app/utils/hooks/useAnalyticsLog";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import LinkifyTags from "../Conversation/LinkifyTags";
import { events } from "../../app/utils/analytics/events";

export type ErrorKeys = keyof typeof errorOptions;
type ErrorContainerProps = {
  error: ErrorKeys;
  header?: boolean;
};

const errorOptions = {
  generic: {
    title: "Whoops",
  },
  noToken: {
    title: "We can't connect you",
  },
  disconnected: {
    title: "Disconnected",
  },
  cannotConnect: {
    title: "We can't connect you",
  },
  openedInAnotherTab: {
    title: "It looks like this conversation is open somewhere else.",
  },
  conversationCleared: {
    title: "Conversation Cleared",
  },
  notFound: {
    title: "Not found",
  },
};

const ErrorContainer = ({ error, header }: ErrorContainerProps) => {
  useAnalyticsLog(`${events.error} ${errorOptions[error].title}`);

  const classes = useStyles();
  const settings = useSelector(selectPartnerSettings);
  return (
    <MainContainer type="error" header={header}>
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.errorContainer}
      >
        <Grid item>
          <Typography variant="h2">{errorOptions[error].title}</Typography>
        </Grid>
        <Grid item>
          {settings.staticPages[error].description.split("\n").map((item) => (
            <Typography key={item} variant="body1">
              <LinkifyTags text={item} />
            </Typography>
          ))}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ErrorContainer;
