import { delay, put, select, takeEvery } from "redux-saga/effects";
import BackoffStrategies from "backoff-strategies";
import { doConnect, onClose } from "app/redux/actions/sockets";
import {
  clearConversation,
  openedInAnotherTab,
} from "app/redux/slices/conversationSlice";
import { combineSagas } from "app/utils/sagaUtils";
import {
  selectConversationStatus,
  selectUser,
} from "app/redux/selectors/conversationSelector";
import { push } from "redux-first-history";
import AppNamedRoutes from "AppNamedRoutes";
import { SocketCloseCodes, SocketHelper } from "app/utils/socketHelper";
import Analytics from "app/utils/analytics";
import { SagaEffect } from "../../sharedTypes/SagaTypeHelper";
import { User } from "../slices/authSlice";

const socketRetryStrategy = new BackoffStrategies.Exponential({
  multiplier: 1_000,
  factor: 2,
  minValue: 1_000,
  maxValue: 32_000,
});

function* socketCloseSaga() {
  yield takeEvery(onClose, function* (closeAction): SagaEffect<User | string> {
    // check the reason of the error and see if we should reconnect
    // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent

    const { code } = closeAction.payload;
    Analytics.log("Passive Action: Socket Closing", closeAction.payload);
    const user = yield select(selectUser);
    const convoStatus = yield select(selectConversationStatus);

    if (SocketHelper.isGracefulClose(code)) {
      // do nothing to reconnect
      const {
        CLOSE_APP_OPENED_SOMEWHERE_ELSE,
        CLOSE_APP_LONG_INACTIVITY,
        CLOSE_APP_IDLE_TIMEOUT,
      } = SocketCloseCodes;
      if (code === CLOSE_APP_OPENED_SOMEWHERE_ELSE) {
        yield put(openedInAnotherTab());
        return;
      } else if (
        [CLOSE_APP_LONG_INACTIVITY, CLOSE_APP_IDLE_TIMEOUT].includes(code)
      ) {
        yield put(clearConversation());
      } else if (convoStatus !== "closed") {
        yield put(push(AppNamedRoutes.home));
      }
    } else if (SocketHelper.isClosedUnexpectedly(code)) {
      const waitTime = socketRetryStrategy.next();
      yield delay(waitTime);
      yield put(doConnect({ user: user as User, reconnecting: true }));
    } else {
      // we don't know what happened we should either reconnect
      // or show an error page.
    }
  });
}

export default combineSagas([socketCloseSaga]);
