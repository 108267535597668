import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import { sendMessage } from "../../redux/actions/conversation";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function useOnWindowClosed() {
  const setting = useSelector(selectPartnerSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    window.onbeforeunload = function () {
      if (setting.tracking.trackWindowClosed) {
        dispatch(
          sendMessage({
            message: "CHAT_WINDOW_CLOSED",
            systemGenerated: true,
          })
        );
      }
    };
  }, [dispatch, setting.tracking.trackWindowClosed]);
}

export default useOnWindowClosed;
