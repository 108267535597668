import { Button } from "@material-ui/core";
import OpenInNewTabIconHelper from "components/StyledMaterialUI/OpenInNewTabHelper";
import useStyles from "./styles";
import { generalSettings } from "app/utils/generalSettings";

function Footer() {
  const classes = useStyles();

  const { url, ariaLabel, linkText, textBefore } = generalSettings.footerLink;

  return (
    <div className={classes.footer}>
      <span className={classes.title}>
        {textBefore}{" "}
        <Button
          aria-label={ariaLabel}
          role="link"
          variant="text"
          color="secondary"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.readMoreOnSiteText}
          style={{ backgroundColor: "transparent" }}
        >
          {linkText}
          <OpenInNewTabIconHelper />
        </Button>
      </span>
    </div>
  );
}
export default Footer;
