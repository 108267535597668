import React, { useEffect } from "react";
import { useAnalyticsLog } from "app/utils/hooks/useAnalyticsLog";
import useChangeTitle from "app/utils/hooks/useChangeTitle";
import useInitConversation from "app/utils/hooks/useInitConversation";
import Splash from "../Splash";
import { useSendInitialMessage } from "../../app/utils/hooks/useSendMessage";
import { events } from "../../app/utils/analytics/events";

function NewConvoIframe() {
  useAnalyticsLog(events.navigation.introNew);
  useChangeTitle(
    "Connect with a crisis counselor via an online text conversation"
  );
  const initConversation = useInitConversation();
  const sendInitialMessage = useSendInitialMessage();

  useEffect(() => {
    initConversation();
    sendInitialMessage();
  });

  return <Splash showCTLLogo={false} />;
}

export default NewConvoIframe;
