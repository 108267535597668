import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import useStyle from "./styles";
import Timer from "react-compound-timer";
import RoundedDialog from "components/StyledMaterialUI/RoundedDialog";
import Transition from "./Transition";
import useChangeTitleFlashing from "app/utils/hooks/useChangeTitleFlashing";
import { useAnalyticsLogWithCondition } from "app/utils/hooks/useAnalyticsLog";
import { usePageVisibility } from "react-page-visibility";
import { secToMs } from "app/utils/converter";
import { events } from "../../app/utils/analytics/events";

type IdleTimeoutDialogProps = {
  isOpen: boolean;
  onClick: () => void;
  timeoutReminder: number;
};

export default function IdleTimeoutDialog({
  isOpen,
  onClick,
  timeoutReminder,
}: IdleTimeoutDialogProps) {
  const classes = useStyle();

  useAnalyticsLogWithCondition(events.popups.idleTimeout, isOpen);
  const isPageVisible = usePageVisibility();

  useChangeTitleFlashing({
    shouldChangeTitle: !isPageVisible && isOpen,
    title: `Are you still here?`,
  });
  return (
    <RoundedDialog
      open={isOpen}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      style={{
        textAlign: "center",
      }}
      onClose={onClick}
    >
      <DialogTitle>Are you still here?</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={onClick}>
            I'm still here
          </Button>
        </Grid>

        <Typography variant={"body2"} className={classes.clearPadding}>
          It looks like you haven't sent or received a message in several
          minutes. That's okay, but we just want to check that you're still
          here. If not, for your privacy, your conversation will be closed
          in&nbsp;
          <Timer initialTime={secToMs(timeoutReminder)} direction="backward">
            <Timer.Minutes /> minutes&nbsp;
            <Timer.Seconds /> seconds.
          </Timer>
        </Typography>
      </DialogContent>
    </RoundedDialog>
  );
}
