import useStyles from "./styles";
import { ChangeEvent, useCallback, useState } from "react";
import StopButton from "./StopButton";
import TextComposer from "./TextComposer";
import SendMessage from "./SendMessage";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/store";
import { active } from "app/redux/slices/IdleSlice";
import { selectIdleState } from "app/redux/selectors/idleSelector";

type ChatBoxProps = {
  minLength: number;
  maxLength: number;
  label: string;
  onSendMessageClick: (message: string) => void;
  onStopClick: () => void;
  isConnected: boolean;
};

const ChatBox = ({
  minLength,
  maxLength,
  label,
  onSendMessageClick,
  onStopClick,
  isConnected,
}: ChatBoxProps) => {
  const settings = useSelector(selectPartnerSettings);
  const dispatch = useAppDispatch();
  const idleState = useSelector(selectIdleState);

  const [message, setMessage] = useState<string>("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState<boolean>(true);
  const [error, serError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");

  const onTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      // trim spaces and  newlines
      let newMessage = event.target.value;
      if (newMessage === " " || newMessage === "\n") {
        newMessage = "";
      }
      setMessage(newMessage);
      const { length } = newMessage;
      const minLengthMessage = `${settings.ui.chatboxTooShortPreText} ${minLength} ${settings.ui.chatboxTooShortPostText}`;
      const maxLengthMessage = `${settings.ui.chatboxTooLongPreText} ${maxLength} ${settings.ui.chatboxTooLongPostText}`;

      if (length === 0) {
        setSendButtonDisabled(true);
        serError(false);
        setHelperText("");
      } else if (length <= minLength) {
        setSendButtonDisabled(true);
        serError(true);
        setHelperText(minLengthMessage);
      } else if (length > maxLength) {
        setSendButtonDisabled(true);
        serError(true);
        setHelperText(maxLengthMessage);
      } else {
        setSendButtonDisabled(false);
        serError(false);
        setHelperText("");
      }
    },
    [minLength, maxLength, settings]
  );

  const onSendMessage = () => {
    if (!disableSend && message && message !== "\n") {
      setSendButtonDisabled(true);
      onSendMessageClick(message);
      setMessage("");
      if (settings.idleMessageStyle === "inline" && idleState === "idleSoon") {
        dispatch(active());
      }
    }
  };

  const disableSend = sendButtonDisabled || !isConnected;

  const classes = useStyles();

  return (
    <div>
      <TextComposer
        onSendMessage={onSendMessage}
        onTextFieldChange={onTextFieldChange}
        label={label}
        error={error}
        helperText={helperText}
        maxLength={maxLength}
        message={message}
      />
      <div className={classes.actionContainer}>
        <div>
          <StopButton
            onStopClick={onStopClick}
            variant={settings.stopButtonVariant}
          />
        </div>
        <div>
          <SendMessage
            disableSend={disableSend}
            onSendMessage={onSendMessage}
            isConnected={isConnected}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
