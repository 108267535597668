import MessageTime from "./MessageTime";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import theme from "app/utils/theme";
import { Button } from "@material-ui/core";
import Analytics from "../../app/utils/analytics";
import { useSelector } from "react-redux";
import { selectPartnerSettings } from "../../app/redux/selectors/conversationSelector";
import LinkifyTags from "./LinkifyTags";
import { events } from "../../app/utils/analytics/events";

type SystemMessageProps = {
  message: string;
  title: string;
  sendAt: number;
  color?: string;
};
export default function SystemMessage({
  message,
  title,
  sendAt,
  color = "blue",
}: SystemMessageProps) {
  const classes = useStyles();
  const setting = useSelector(selectPartnerSettings);

  const getBackgroundColor = () => {
    switch (color) {
      case "blue":
        return `${theme.palette.colors988.lightBlue}`;
      case "yellow":
        return `${theme.palette.colors988.yellow}`;
    }
  };

  const getTextColor = () => {
    switch (color) {
      case "blue":
        return `${theme.palette.colors988.darkerBlue}`;
      case "yellow":
        return `${theme.palette.colors988.brown}`;
    }
  };

  const gridSize = setting.startMessage.showTime === "inline" ? 10 : 12;

  return (
    <Grid
      container
      style={{ background: getBackgroundColor(), paddingLeft: 16 }}
    >
      {setting.startMessage.showTime === "inline" && (
        <Grid item xs={2} className={classes.systemMessageTime}>
          <MessageTime time={sendAt} />
        </Grid>
      )}
      <Grid item xs={gridSize}>
        {title && (
          <p
            className={classes.systemMessageTitle}
            style={{ color: getTextColor() }}
          >
            {title}
          </p>
        )}
        <p className={classes.systemMessage} style={{ color: getTextColor() }}>
          <LinkifyTags
            text={message}
            renderItem={(href, text, index) => {
              return (
                <Button
                  role="link"
                  variant="text"
                  color="secondary"
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ backgroundColor: "transparent", padding: "revert" }}
                  className={classes.systemMessageLink}
                  key={index}
                  onClick={() => {
                    Analytics.log(events.userActions.clickLink, { href: href });
                  }}
                >
                  {text}
                </Button>
              );
            }}
          />
        </p>
      </Grid>
    </Grid>
  );
}
