import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

type InfoCardProps = {
  illustration: string;
  title: string;
  children: React.ReactNode;
};
const InfoCard = ({ children, title, illustration }: InfoCardProps) => {
  const classes = useStyles();
  return (
    <section style={{ width: "300px" }}>
      <div className={classes.moreInfoCard}>
        <div>
          <img src={illustration} alt={title} className={classes.moreInfoImg} />
        </div>
        <div>
          <Typography className={classes.moreInfoTitle}>{title}</Typography>
        </div>
        <div>
          <Typography variant="body2">{children}</Typography>
        </div>
      </div>
    </section>
  );
};

export default InfoCard;
