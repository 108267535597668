let defaultPageIcon = document.getElementById("favicon") as HTMLLinkElement;
// for testing
if (!defaultPageIcon) {
  defaultPageIcon = document.createElement("link");
}

const faviconMap = {
  notification: "favicons/favicon-notification.ico",
  default: "favicons/favicon.ico",
};

export type Favicon = keyof typeof faviconMap;

export const changeTitle = (title: string, icon: Favicon) => {
  document.title = title;
  defaultPageIcon.href = faviconMap[icon];
};
