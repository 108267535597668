import Amplitude from "amplitude-js";
import ReactGA4 from "react-ga4";
import generalSettings from "./settings";
import type { InitOptions } from "react-ga4/types/ga4";

class Analytics {
  ga4Initialize = false;
  setUp = () => {
    if (generalSettings.amplitudeApiKey) {
      Amplitude.getInstance().init(generalSettings.amplitudeApiKey, undefined, {
        logLevel: "ERROR",
      });
    }

    const ga4Settings = generateGA4Options([
      process.env.REACT_APP_TRACKING_ID, // Google Analytics property for the whole org
      process.env.REACT_APP_GA_MEASUREMENT_ID, // Google Analytics property for Connect
    ]);

    if (ga4Settings.length) {
      ReactGA4.initialize(ga4Settings);
      this.ga4Initialize = true;
    }
  };

  log = (event: string, extraData?: object): void => {
    const [category = "general", action = event] = event.split(":");
    if (this.ga4Initialize) {
      ga4Event(category, action, extraData);
    }
    if (Amplitude.getInstance().getSessionId()) {
      Amplitude.getInstance().logEvent(event, extraData);
    }
  };
}

function ga4Event(category: string, action: string, extraData?: object) {
  if (extraData) {
    ReactGA4.set(extraData);
  }

  ReactGA4.event({ category, action });
}

function generateGA4Options(trackerIds: (string | void)[]): InitOptions[] {
  const options: InitOptions[] = [];

  trackerIds.forEach((trackingId) => {
    if (!!trackingId) {
      options.push({ trackingId });
    }
  });

  return options;
}

const analytics = new Analytics();

export default analytics;
