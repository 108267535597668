import generalSettings from "./settings";

let internalSocket;
export const SocketCloseCodes = {
  CLOSE_NORMAL: 1000,
  CLOSE_GOING_AWAY: 1001,
  CLOSE_ABNORMAL: 1006,
  CLOSE_APP_ABNORMAL: 3000,
  CLOSE_APP_NORMAL: 4000,
  CLOSE_APP_OPENED_SOMEWHERE_ELSE: 4001,
  CLOSE_APP_LONG_INACTIVITY: 4002,
  CLOSE_APP_IDLE_TIMEOUT: 4003,
};

export const SocketHelper = {
  connect(identity: string) {
    internalSocket = new WebSocket(
      `${generalSettings.socketUrl}?i=${identity}`
    );
    return internalSocket;
  },
  isGracefulClose: (code: number) => {
    return [
      SocketCloseCodes.CLOSE_NORMAL,
      SocketCloseCodes.CLOSE_APP_NORMAL,
      SocketCloseCodes.CLOSE_APP_OPENED_SOMEWHERE_ELSE,
      SocketCloseCodes.CLOSE_APP_LONG_INACTIVITY,
      SocketCloseCodes.CLOSE_APP_IDLE_TIMEOUT,
    ].includes(code);
  },
  isClosedUnexpectedly: (code: number) => {
    return [
      SocketCloseCodes.CLOSE_GOING_AWAY,
      SocketCloseCodes.CLOSE_ABNORMAL,
      SocketCloseCodes.CLOSE_APP_ABNORMAL,
    ].includes(code);
  },
};
