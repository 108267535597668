import { Button, FormControl } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";

type SendMessageProps = {
  disableSend: boolean;
  onSendMessage: () => void;
  isConnected: boolean;
};
const SendMessage = ({
  disableSend,
  onSendMessage,
  isConnected,
}: SendMessageProps) => {
  const setting = useSelector(selectPartnerSettings);
  const classes = useStyles();

  const NO_CONNECTION_MESSAGE = "No Connection";

  return (
    <FormControl variant="outlined">
      <Button
        disabled={disableSend}
        variant="contained"
        color={setting.ui.sendMessageButtonColor}
        onClick={onSendMessage}
        aria-label={
          isConnected ? setting.ui.sendMessageButtonText : NO_CONNECTION_MESSAGE
        }
        title={
          isConnected ? setting.ui.sendMessageButtonText : NO_CONNECTION_MESSAGE
        }
        className={clsx({
          [classes.connectionErrorButton]: !isConnected,
          [classes.send]: isConnected,
        })}
      >
        {isConnected ? setting.ui.sendMessageButtonText : NO_CONNECTION_MESSAGE}
      </Button>
    </FormControl>
  );
};

export default SendMessage;
