import React from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import { BluetoothConnectedOutlined, Clear, Star } from "@material-ui/icons";
import { addUserAuth } from "app/redux/slices/authSlice";
import { useAppDispatch } from "app/store";
import { emitSocketAction } from "app/redux/actions/conversation";
import { SocketCloseCodes } from "app/utils/socketHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
    },
    exampleWrapper: {
      position: "relative",
      marginTop: theme.spacing(3),
      height: 380,
    },
    radioGroup: {
      margin: theme.spacing(1, 0),
    },
    speedDial: {
      position: "absolute",
      "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  })
);

export default function DevTools() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <SpeedDial
      ariaLabel="Debug Tools => Quick Action access"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction="up"
    >
      <SpeedDialAction
        key={"Close Connection open in another tab."}
        icon={<Star />}
        tooltipTitle={"Close Connection open in another tab."}
        title={"Close Connection open in another tab."}
        onClick={() => {
          window.ctlSocket.close(
            SocketCloseCodes.CLOSE_APP_OPENED_SOMEWHERE_ELSE,
            "Close Connection open in another tab."
          );
          handleClose();
        }}
      />
      <SpeedDialAction
        key={"request conversation status."}
        icon={<Star />}
        tooltipTitle={"request conversation status."}
        title={"request conversation status."}
        onClick={() => {
          dispatch(
            emitSocketAction({ action: "getConversationStatus", data: {} })
          );
        }}
      />
      <SpeedDialAction
        key={"close backend connection"}
        icon={<PortableWifiOffIcon />}
        tooltipTitle={"close backend connection"}
        title={"close backend connection"}
        onClick={() => {
          window.ctlSocket.close(
            SocketCloseCodes.CLOSE_NORMAL,
            "server triggered none faulty error code"
          );
          handleClose();
        }}
      />
      <SpeedDialAction
        key={"Temporary disconnect"}
        icon={<BluetoothConnectedOutlined />}
        tooltipTitle={"Temporary disconnect"}
        onClick={() => {
          window.ctlSocket.close(
            SocketCloseCodes.CLOSE_APP_ABNORMAL,
            "user triggered none faulty error code"
          );
          handleClose();
        }}
        title={"Temporary disconnect"}
      />

      <SpeedDialAction
        key={"clear identity"}
        icon={<Clear />}
        tooltipTitle={"clear identity"}
        onClick={() => {
          dispatch(
            addUserAuth({
              identity: "",
              partner: "CTL",
              iframe: false,
              lang: "en",
            })
          );
          handleClose();
        }}
      />
    </SpeedDial>
  );
}
