import GetToken from "../components/Intro/GetToken";
import useQuery from "../hooks/useQuery";
import { useEffect } from "react";
import { useAppDispatch } from "../app/store";
import { setDefaultKeyword } from "app/redux/slices/conversationSlice";

const KEYWORD_KEY = "k";

function Partner() {
  const query = useQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const keyword = query.get(KEYWORD_KEY);
    if (keyword && keyword?.length >= 2) {
      dispatch(setDefaultKeyword(keyword));
      // remove from the url.
      window.history.replaceState(
        null,
        "",
        window.location.href.replace(`${KEYWORD_KEY}=${keyword}`, "")
      );
    }
  });

  return (
    <div className="App" style={{ textAlign: "center", height: "100%" }}>
      <GetToken />
    </div>
  );
}

export default Partner;
