import { Partner, User } from "../redux/slices/authSlice";

export const getAnchorTagsFromString = (message: string) => {
  return message.split(/<a href='(.*?)'>(.*?)<\/a>/g);
};

type GetStartMessageText = (
  map: Map<Partner, string>,
  user: User,
  defaultValue: string
) => string;

export const getStringBasedOnPartner: GetStartMessageText = (
  map,
  user,
  defaultValue = ""
) => {
  const partner = user.partner.toUpperCase() as Partner;
  if (map.has(partner) && map.get(partner)) {
    return map.get(partner) as string;
  }

  return defaultValue;
};
