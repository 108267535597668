import { combineSagas } from "app/utils/sagaUtils";
import { Status } from "../slices/conversationSlice";
import { put, select, takeEvery } from "redux-saga/effects";
import { selectConversationStatus } from "../selectors/conversationSelector";
import { closeConnection, updateConversationStatus } from "../actions/sockets";
import { SocketCloseCodes } from "app/utils/socketHelper";
import Analytics from "app/utils/analytics";

function* handleConversationStatusUpdated() {
  yield takeEvery(updateConversationStatus, function* (action) {
    let { status } = action.payload;

    const currentConversationStatus: Status = yield select(
      selectConversationStatus
    );

    if (currentConversationStatus === "ongoing" && status === "new") {
      Analytics.log(
        "Passive action: system closed connection due to long term disconnect"
      );
      yield put(
        closeConnection({ code: SocketCloseCodes.CLOSE_APP_LONG_INACTIVITY })
      );
    }
  });
}

export default combineSagas([handleConversationStatusUpdated]);
