import generalSettings from "./settings";

const getUrl = (path: string) => {
  return `${generalSettings.apiUrl}/${path}`;
};

type getIdentityResponse = {
  identity: string;
  provider: string;
};

export const getIdentity = async (
  token: string,
  signal?: AbortSignal
): Promise<getIdentityResponse> => {
  const response = await fetch(getUrl("identity"), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
    }),
    ...(signal && { signal: signal }),
  });

  if (![200, 201].includes(response.status)) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.json();
};

export const getPartnerToken = async (
  reCaptchaCode: string,
  signal?: AbortSignal
) => {
  const response = await fetch(getUrl("partner-token"), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      recaptcha: reCaptchaCode,
    }),
    ...(signal && { signal: signal }),
  });

  if (![200, 201].includes(response.status)) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.json();
};
