import {
  DEFAULT_FAIL_MESSAGE_AFTER,
  DEFAULT_TIMEOUT,
  DEFAULT_TIMEOUT_REMINDER,
  staticPageDefaultContent,
  cannotConnectDescCTL,
  PartnerSetting,
} from "./defaults";

const disconnectDisclaimer = `
    Please note that while you chat with us you must remain on the website.
    If you navigate away from the chat or go to another app your connection
    may be lost.
`;

const SETTINGS_CLASSROOM: PartnerSetting = {
  misc: {
    keyword: "STUDENT",
    phoneNumber: "741741",
    whatsAppNumber: "14437877678",
  },
  timeouts: {
    timeoutReminder: process.env.REACT_APP_IDLE_TIMEOUT_REMINDER
      ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT_REMINDER)
      : DEFAULT_TIMEOUT_REMINDER,
    timeToTimeout: process.env.REACT_APP_IDLE_TIMEOUT
      ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT)
      : DEFAULT_TIMEOUT,
    failMessageAfter: DEFAULT_FAIL_MESSAGE_AFTER,
    timeToClearConversationAfterClose: 600,
    clearConversationAfterClose: true,
  },
  disconnectDisclaimer,
  startMessage: {
    showStartMessageText: false,
    startMessageText: "",
    showTime: "none",
  },
  stopButtonVariant: "complex",
  idleMessageStyle: "popup",
  autoStartConversation: false,
  showMenu: true,
  tracking: {
    trackWindowClosed: false,
  },
  ui: {
    stopButtonText: {
      simple: "End Chat",
      complex: "Need to Leave?",
    },
    cancelButtonText: "Cancel",
    okButtonText: "Ok",
    chatboxLabelText: "Type your message here",
    chatboxTooShortPreText:
      "Your message is too short. Please increase to at least",
    chatboxTooShortPostText: "characters to send.",
    chatboxTooLongPreText: "Your message is too long. Please limit to",
    chatboxTooLongPostText: "characters to send.",
    closeConvoPreText:
      "You've left the chat. For immediate assistance, please call",
    closeConvoPostText: "or refresh the page to rejoin the queue.",
    sendMessageButtonText: "Send message",
    sendMessageButtonColor: "primary",
    closeConversationDialog: {
      leaveChat: "If you need to leave, message your Crisis Counselor.",
      leaveChatCompact: "Are you sure you wish to end this chat session?",
    },
    yourConversationHasBeenClosed: "Your conversation has been closed.",
  },
  staticPages: {
    generic: {
      description: staticPageDefaultContent.generic.description,
    },
    noToken: {
      description: cannotConnectDescCTL,
    },
    disconnected: {
      description: staticPageDefaultContent.disconnected.description,
    },
    cannotConnect: {
      description: cannotConnectDescCTL,
    },
    openedInAnotherTab: {
      description: staticPageDefaultContent.openedInAnotherTab.description,
    },
    conversationCleared: {
      description: staticPageDefaultContent.conversationCleared.description,
    },
    notFound: {
      description: staticPageDefaultContent.notFound.description,
    },
  },
};

export default SETTINGS_CLASSROOM;
