import { FormGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SoundSwitch from "../StyledMaterialUI/SoundSwitch";
import { useAppDispatch } from "app/store";
import { toggleSound } from "app/redux/slices/menuSlice";
import { selectIsSoundOn } from "app/redux/selectors/menuSelector";
import { useSelector } from "react-redux";

function CustomizedSwitch() {
  const dispatch = useAppDispatch();

  const soundOn = useSelector(selectIsSoundOn);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <SoundSwitch
            inputProps={{ "aria-label": "Sound Notification Toggle" }}
            onChange={() => {
              dispatch(toggleSound());
            }}
            checked={soundOn}
            data-testid="sound-switch"
          />
        }
        label=""
      />
    </FormGroup>
  );
}

export default CustomizedSwitch;
