import { getAnchorTagsFromString } from "../../app/utils/stringUtils";

type RenderItemType = (
  href: string,
  text: string,
  index: number
) => JSX.Element;
type LinkifyTagsProps = {
  text: string;
  renderItem?: RenderItemType;
};

const simpleRenderItem: RenderItemType = (
  href: string,
  text: string,
  index: number
) => {
  return (
    <a href={href} key={index} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

function LinkifyTags({
  text,
  renderItem = simpleRenderItem,
}: LinkifyTagsProps) {
  const parts = getAnchorTagsFromString(text);
  let savedIndex: number | null = null;
  return (
    <span key={text}>
      {parts
        .map((part, index) => {
          if (index === savedIndex) {
            return "";
          }
          if (
            part.includes("https") ||
            part.includes("http") ||
            part.includes("tel:") ||
            part.includes("sms:") ||
            part.includes("mailto:")
          ) {
            savedIndex = index + 1;
            return renderItem(part, parts[index + 1], index);
          }
          return part;
        })
        .filter(Boolean)}
    </span>
  );
}

export default LinkifyTags;
