import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  about: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    marginTop: "8vh",
    paddingLeft: "6%",
    paddingRight: "6%",
    marginBottom: "45px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.down(240)]: {
      paddingLeft: "1%",
      paddingRight: "1%",
    },
  },
  newConvoInfo: {
    maxWidth: "350px",
  },
  newConvoImg: {
    marginTop: 22,
    display: "inline",
  },
  agreeToConditions: {
    paddingTop: "20px",
    display: "flex",
    alignItems: "left",
    textAlign: "left",
    marginRight: "1px",
    maxWidth: "324px",
  },
  labelTypography: {
    textAlign: "left",
  },
  agreeText: {
    paddingTop: theme.spacing(1),
    display: "inline",
    fontWeight: 300,
    textAlign: "left",
    color: `${[theme.palette.ctlColors.darkGrey]}`,
  },
  disconnectDisclaimerText: {
    marginTop: "1em",
    paddingTop: theme.spacing(1),
    display: "inline",
    fontWeight: 300,
    textAlign: "left",
    color: `${[theme.palette.ctlColors.darkGrey]}`,
  },
  welcomeText: {
    maxWidth: "298px",
    margin: "auto",
    marginBottom: "15px",
  },
  termsAndConditionsText: {
    color: `${[theme.palette.ctlColors.darkGrey]}`,
    display: "inline",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "1.05rem",
    padding: `0px 0px 0px 2px`,
    textAlign: "left",
  },
  termsAndConditionsIcon: {
    display: "inline-block",
    marginLeft: "0.2em",
    height: ".68em",
    width: ".68em",
    verticalAlign: "text-bottom",
    color: `${[theme.palette.ctlColors.darkGrey]}`,
  },
  ButtonLabel: {},
  moreInfoContainer: {
    backgroundColor: `${[theme.palette.grey[200]]}`,
    width: "100vw",
    maxWidth: "100vw",
    margin: 0,
  },
  moreInfoCard: {
    border: `1px solid ${[theme.palette.grey[400]]}`,
    borderRadius: "14px",
    backgroundColor: "#FFF",
    textAlign: "left",
    margin: theme.spacing(3, 2, 2, 2),
    padding: "15px",
  },
  moreInfoTitle: {
    color: `${theme.palette.ctlColors.red}`,
    fontWeight: 600,
    textTransform: "uppercase",
    textAlign: "left",
    padding: "25px 0px 12px 0px",
    letterSpacing: "0.1em",
    marginBottom: "5px",
  },
  moreInfoImg: {
    width: "90px",
    height: "72px",
    alignSelf: "flex-start",
    marginTop: "5px",
  },
  screenReaderOnly: {
    position: "absolute",
    width: "1px",
    clip: "rect(0,0,0,0)",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  whiteSpace: {
    whiteSpace: "nowrap",
  },
  recaptchaContainer: {
    paddingTop: "16px",
  },
}));
