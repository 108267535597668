import { delay, select, takeLeading } from "redux-saga/effects";
import { playSoundAction } from "app/redux/actions/conversation";
import playSound from "app/utils/sound";
import { selectIsSoundOn } from "app/redux/selectors/menuSelector";

function* soundSaga() {
  yield takeLeading(playSoundAction, function* ({ payload }) {
    const isSoundOn: boolean = yield select(selectIsSoundOn);
    if (isSoundOn) {
      playSound(payload);
    }
    yield delay(1000);
  });
}

export default soundSaga;
