const soundMap = {
  internal: "/internal.wav",
  default: "/internal.wav",
};

export type Sound = keyof typeof soundMap;

type loadedHTMLAudioElementObject = {
  [key in Sound]?: HTMLAudioElement;
};

let loadedSounds: loadedHTMLAudioElementObject = {};

/**
 * in Safary we can only play sound on user interaction.
 * so we load the sound on user interaction and replay
 * it later on async work
 * @param sound
 */
export const loadSound = (sound: Sound) => {
  const htmlAudioElement = new Audio(soundMap[sound]);
  htmlAudioElement.load();
  htmlAudioElement.addEventListener("canplaythrough", () => {
    loadedSounds[sound] = htmlAudioElement;
  });
};

const playSound = (sound: Sound) => {
  let soundFile = loadedSounds[sound];
  /**
   * sound should be loaded in step 1 above, but if this didn't happen
   * we try to load the sound and play it.
   */
  if (!soundFile) {
    soundFile = new Audio(soundMap[sound]);
  }

  soundFile.volume = 0.4;
  soundFile.play().catch((error) => {
    console.log(error);
  });
};

export default playSound;
