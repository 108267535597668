import { createSlice } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

type SliceState = {
  isMenuOpen: boolean;
  isSoundOn: boolean;
};

const initialState: SliceState = { isMenuOpen: false, isSoundOn: false };

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isMenuOpen = true;
    },
    closeMenu: (state) => {
      state.isMenuOpen = false;
    },
    toggleSound: (state) => {
      state.isSoundOn = !state.isSoundOn;
    },
  },
});

export const { openMenu, closeMenu, toggleSound } = menuSlice.actions;

// export default menuSlice.reducer;

const persistSoundConfig = {
  key: "isSoundOn",
  storage: sessionStorage,
  whitelist: ["isSoundOn"],
  version: 1,
};

const menuSliceReducer = persistReducer(persistSoundConfig, menuSlice.reducer);

export default menuSliceReducer;
