import type { RootState } from "app/redux/slices/rootReducer";
import { PARTNER_988, PARTNER_GOOGLE } from "../slices/authSlice";
import { getSettingsForPartner } from "app/utils/settings";
import { PartnerSetting } from "../../utils/partnerSettings/defaults";

export const IFRAME_UI_PARTNERS = [PARTNER_988, PARTNER_GOOGLE];

export const selectMessages = (state: RootState) => {
  return state.conversation.messages;
};

export const selectUser = (state: RootState) => {
  return state.auth.user;
};

export const selectUserPartner = (state: RootState) => {
  return state.auth.user.partner;
};
export const selectIsIframeUI = (state: RootState) => {
  return state.auth.user.iframe;
};

export const selectIs988 = (state: RootState) => {
  return state.auth.user.partner === PARTNER_988;
};

export const selectDefaultKeyword = (state: RootState): string | false => {
  return state.conversation.defaultKeyword ?? false;
};

export const selectConversationStatus = (state: RootState) => {
  return state.conversation.status;
};

export const selectFirstMessagesByContent = (
  state: RootState,
  needle: string
) => {
  return Object.values(state.conversation.messages).find(
    (message) => message.body === needle
  );
};

export const selectPartnerSettings = (state: RootState): PartnerSetting => {
  return getSettingsForPartner(state.auth.user.partner, state.auth.user.lang);
};
