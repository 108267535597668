import { idle, idleSoon } from "../redux/slices/IdleSlice";
import { Action } from "@reduxjs/toolkit";
import { Dispatch } from "react";

const onWorkerData =
  (dispatch: Dispatch<Action>) =>
  ({ data }: any) => {
    const actionMap = {
      [idleSoon.toString()]: idleSoon,
      [idle.toString()]: idle,
    };

    if (!actionMap.hasOwnProperty(data.action)) {
      console.log(`${data.action} is not configured"`);
      return;
    }
    dispatch(actionMap[data.action]());
  };

class IdleTimeoutWorker {
  private worker: Worker;

  constructor() {
    this.worker = new Worker(new URL("timer.worker", import.meta.url));
  }

  addEventListener(dispatch: Dispatch<Action>) {
    this.worker.addEventListener("message", onWorkerData(dispatch));
  }

  removeEventListener(dispatch: Dispatch<Action>) {
    this.worker.addEventListener("message", onWorkerData(dispatch));
  }

  send(obj: object) {
    this.worker.postMessage(obj);
  }
}

export default IdleTimeoutWorker;
