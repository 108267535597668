import { Message } from "app/redux/slices/conversationSlice";
import { Done, DoneAll } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
import React from "react";
import { retrySendMessage } from "app/redux/actions/conversation";
import { useAppDispatch } from "app/store";

export default function MessageStatus(message: Message) {
  const { isMine, status } = message;
  const dispatch = useAppDispatch();

  if (!isMine) {
    return <></>;
  }

  if (status === "sending") {
    return <>Sending...</>;
  } else if (status === "sent") {
    return (
      <Done
        fontSize={"small"}
        viewBox="0 0 24 10"
        style={{ paddingRight: "4px" }}
        titleAccess={"sent icon"}
      />
    );
  } else if (status === "delivered") {
    return <DoneAll />;
  }

  return (
    <span style={{ display: "flex" }}>
      <Typography color="error">Message not sent.</Typography>
      <Button
        variant="text"
        onClick={() => {
          dispatch(retrySendMessage(message));
        }}
        style={{
          textDecoration: "underline",
          padding: "0 0 0 5px",
          lineHeight: "1.188rem",
        }}
      >
        Try again
      </Button>
    </span>
  );
}
