import { useEffect } from "react";
import { Sound } from "app/utils/sound";
import { useAppDispatch } from "app/store";
import { playSoundAction } from "app/redux/actions/conversation";
import { changeTitle, Favicon } from "../changeTitle";

type useChangeTitleProps = {
  shouldChangeTitle: boolean;
  title: string;
  favicon?: Favicon;
  sound?: Sound;
};

const useChangeTitleFlashing = ({
  shouldChangeTitle,
  title,
  favicon = "default",
  sound = "default",
}: useChangeTitleProps) => {
  const dispatch = useAppDispatch();
  const defaultPageTitle = document.title;
  useEffect(() => {
    if (!shouldChangeTitle) {
      return;
    }

    dispatch(playSoundAction(sound));

    let i = 0;
    const interval = setInterval(() => {
      if (i % 2 === 1 && shouldChangeTitle) {
        changeTitle(title, favicon);
      } else {
        changeTitle(defaultPageTitle, "default");
      }
      i += 1;
    }, 1000);

    return () => {
      changeTitle(defaultPageTitle, "default");
      clearInterval(interval);
    };
  }, [shouldChangeTitle, favicon, title, sound, defaultPageTitle, dispatch]);
};

export default useChangeTitleFlashing;
