import { createSlice } from "@reduxjs/toolkit";
import { onClose, onOpen } from "app/redux/actions/sockets";

type SliceState = {
  connected: boolean;
  isFirstLoad: boolean;
};

const initialState: SliceState = {
  connected: false,
  isFirstLoad: true,
};

export const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onOpen, (state, action) => {
      state.connected = true;
      state.isFirstLoad = false;
    });
    builder.addCase(onClose, (state, action) => {
      state.connected = false;
    });
  },
});

export default connectionSlice.reducer;
