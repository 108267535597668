import { IconButton } from "@material-ui/core";
import React from "react";
import useStyles from "components/Layout/styles";
import { Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { selectIsMenuOpen } from "app/redux/selectors/menuSelector";
import { openMenu } from "app/redux/slices/menuSlice";
import { useAppDispatch } from "app/store";

const MenuIconButton: any = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const checkIsMenuOpen = useSelector(selectIsMenuOpen);

  const handleDrawerOpen = () => {
    dispatch(openMenu());
  };

  return (
    <IconButton
      edge="end"
      aria-label="open menu"
      className={`${classes.menuIcon} ${clsx(
        checkIsMenuOpen && classes.hideMenu
      )}`}
      onClick={handleDrawerOpen}
      style={{ backgroundColor: "transparent" }}
    >
      <Typography className={classes.menuIconText}> Menu </Typography>
      <MenuIcon />
    </IconButton>
  );
};

export default MenuIconButton;
