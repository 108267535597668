import withStyles from "@material-ui/core/styles/withStyles";
import { Badge } from "@material-ui/core";

const ConnectionBadge = withStyles({
  colorPrimary: {
    background: "green",
  },
})(Badge);

export default ConnectionBadge;
