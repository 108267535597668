import { useAppDispatch } from "app/store";
import useStyle from "../styles";
import { useSelector } from "react-redux";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import AppNamedRoutes from "AppNamedRoutes";
import { clearConversation } from "app/redux/slices/conversationSlice";

const CtlWebchat = () => {
  const dispatch = useAppDispatch();
  const classes = useStyle();
  const setting = useSelector(selectPartnerSettings);

  const smsHref = `sms:${setting.misc.phoneNumber}?&body=${setting.misc.keyword}`;
  const whatsAppHref = `https://wa.me/${setting.misc.whatsAppNumber}?text=${setting.misc.keyword}&lang=en`;

  return (
    <div>
      <Typography variant="body1" className={classes.closedConvoText}>
        For your privacy, we'll take you to our
        <Link
          className={classes.closedConvoTextLink}
          to={setting.misc.customHomepageRoute ?? AppNamedRoutes.home}
          aria-label="chat page"
          onClick={() => {
            dispatch(clearConversation());
          }}
        >
          {" "}
          chat page
        </Link>{" "}
        in ten minutes. You can also{" "}
        <a href={smsHref} target="_blank" rel="noopener noreferrer">
          text {setting.misc.keyword} to {setting.misc.phoneNumber}
        </a>
        {setting.misc.whatsAppNumber && (
          <>
            {" or reach us via "}
            <a target="_blank" rel="noopener noreferrer" href={whatsAppHref}>
              WhatsApp
            </a>
          </>
        )}
        .
      </Typography>
    </div>
  );
};

export default CtlWebchat;
