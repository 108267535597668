import { createSlice } from "@reduxjs/toolkit";
import {
  addMessage,
  messageReceived,
} from "app/redux/slices/conversationSlice";
import type { AnyAction } from "redux";
import { startTimeout } from "../actions/timeout";
import { DateTime } from "luxon";

type IdleState = "active" | "idleSoon" | "idle";
type SliceState = {
  idleState: IdleState;
  conversationStartedAt?: number;
};

const initialState: SliceState = {
  idleState: "active",
  conversationStartedAt: undefined,
};
const conversationActivatorActionsMatcher: (a: AnyAction) => boolean = (
  action
) => {
  return [messageReceived.toString(), addMessage.toString()].includes(
    action.type
  );
};

export const idleSlice = createSlice({
  name: "idle",
  initialState,
  reducers: {
    active: (state) => {
      state.idleState = "active";
    },
    idleSoon: (state) => {
      state.idleState = "idleSoon";
    },
    idle: (state) => {
      state.idleState = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startTimeout, (state) => {
      state.conversationStartedAt = DateTime.now().toSeconds();
    });

    builder.addMatcher(conversationActivatorActionsMatcher, (state) => {
      state.idleState = "active";
    });
  },
});

export const { active, idleSoon, idle } = idleSlice.actions;

export default idleSlice.reducer;
