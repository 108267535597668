import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import OpenInNewTabIconHelper from "../StyledMaterialUI/OpenInNewTabHelper";
import { AcceptTermsType } from "app/utils/generalSettings";

function AcceptTerms({
  url,
  ariaLabel,
  linkText,
  textBefore,
}: AcceptTermsType) {
  const classes = useStyles();

  return (
    <Typography className={classes.agreeText}>
      {textBefore}{" "}
      <a
        color="secondary"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.termsAndConditionsText}
        aria-label={ariaLabel}
        style={{ backgroundColor: "transparent" }}
      >
        {linkText}
        <OpenInNewTabIconHelper />
      </a>
      .
    </Typography>
  );
}

export default AcceptTerms;
