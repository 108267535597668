import { addMessage } from "app/redux/slices/conversationSlice";
import { useAppDispatch } from "app/store";
import { useSelector } from "react-redux";
import {
  selectPartnerSettings,
  selectUser,
} from "app/redux/selectors/conversationSelector";
import { User } from "app/redux/slices/authSlice";
import { nanoid } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

export const START_MESSAGE_TITLE = "Welcome.";

export const generateIdleMessageTitle = (idleSoFarMinutes: number) => `
  You've been idle for ${idleSoFarMinutes} minutes.
`;
export const generateIdleMessageText = (timeLeftToTimeoutMinutes: number) => `
  We haven't heard from you in a while. We're still here for you if you want to
  keep talking. Please send us a message in the next
  ${timeLeftToTimeoutMinutes} minutes to stay in the conversation.
`;

export const generateMessage = (
  message: string,
  title: string,
  user: User,
  color = "blue"
) => {
  return {
    messageId: nanoid(16),
    body: message,
    sendAt: DateTime.local().toSeconds(),
    isMine: true,
    systemGenerated: false,
    systemMessage: true,
    systemMessageTitle: title,
    systemMessageColor: color,
  };
};

export const useSendMessage = (
  messageText: string,
  title: string,
  color?: string
) => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const setting = useSelector(selectPartnerSettings);
  if (!setting.startMessage.showStartMessageText) {
    return () => {};
  }
  return () => {
    const message = generateMessage(messageText, title, user, color);
    dispatch(addMessage(message));
  };
};

export const useSendInitialMessage = () => {
  const setting = useSelector(selectPartnerSettings);

  return useSendMessage(
    setting.startMessage.startMessageText,
    setting.startMessage.startMessageTitle ?? "",
    "blue"
  );
};
