import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { history, persistor, store } from "app/store";
import { Provider } from "react-redux";
import AppRouter from "AppRouter";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "app/utils/theme";
import { CssBaseline } from "@material-ui/core";
import Analytics from "app/utils/analytics";
import generalSettings from "app/utils/settings";
import DevTools from "./DevTools";
import { buildURLSearchParams } from "./app/utils/urlHelper";
import { HistoryRouter as Router } from "redux-first-history/rr6";

require("./global.d");

Analytics.setUp();

if (generalSettings.sentryDns) {
  Sentry.init({
    dsn: generalSettings.sentryDns,
    autoSessionTracking: true,
    integrations: [new BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
const urlSearchParams = buildURLSearchParams(history.location.search);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={history}>
              {generalSettings.enableDebuggingFeatures &&
                urlSearchParams.has("debug") && <DevTools />}
              <AppRouter />
            </Router>
          </ThemeProvider>
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
