import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import type { SocketEventDataMap } from "app/sharedTypes/socketActionTypes";
import storageSession from "redux-persist/lib/storage/session";
import { createTransform, persistReducer } from "redux-persist";
import { addUserAuth } from "./authSlice";
import { DateTime } from "luxon";

export interface Message {
  messageId: string;
  body: string;
  isMine: boolean;
  systemMessage?: boolean;
  systemMessageTitle?: string;
  systemMessageColor?: string;
  sendAt: number;
  status?: "sending" | "sent" | "delivered" | "failed";
  // those below props only exists for outgoing messages incoming message doesn't include it.
  receiveConfirmedAt?: number;
  systemGenerated?: boolean;
}

export type Status =
  | "init"
  | "new"
  | "started"
  | "ongoing"
  | "openedInAnotherTab"
  | "closed"
  | "cleared"
  | "impossible"
  | "impossible-now";

type SliceState = {
  messages: {
    [id: string]: Message;
  };
  status: Status;
  defaultKeyword?: string;
};

const initialState: SliceState = {
  messages: {},
  status: "init",
};

/**
 * @TODO find a way to cover this in a test
 */
const openedInAnotherTabTransformer = createTransform<
  Status,
  Status,
  SliceState,
  SliceState
>(
  (outboundState) => {
    return outboundState;
  },
  (outboundState) => {
    if (outboundState === "openedInAnotherTab") {
      return "ongoing";
    }
    return outboundState;
  },
  { whitelist: ["status"] }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      const {
        body,
        messageId,
        sendAt,
        isMine,
        systemGenerated,
        systemMessage,
        systemMessageTitle,
        systemMessageColor,
      } = action.payload;
      state.messages[messageId] = {
        messageId,
        body,
        sendAt,
        isMine,
        systemGenerated,
        systemMessage,
        systemMessageTitle,
        systemMessageColor,
        status: "sending",
      };
    },
    messageReceived: (
      state,
      action: PayloadAction<SocketEventDataMap["sendMessage"]>
    ) => {
      const { body } = action.payload;
      const messageId = nanoid(16);
      state.messages[messageId] = {
        messageId,
        body,
        sendAt: DateTime.local().toSeconds(),
        isMine: false,
      };
    },
    updateMessageStatus: (
      state,
      action: PayloadAction<SocketEventDataMap["updateStatus"]>
    ) => {
      const { messageId, status } = action.payload;

      if (state.messages[messageId]) {
        state.messages[messageId].status = status;
      }
    },
    closeConversation: (state) => {
      state.status = "closed";
    },
    startConversation: (state) => {
      state.status = "ongoing";
    },
    openedInAnotherTab: (state) => {
      state.status = "openedInAnotherTab";
    },
    clearConversation: (state) => {
      state.status = "init";
      state.messages = {};
    },
    setDefaultKeyword: (state, action: PayloadAction<string>) => {
      state.defaultKeyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addUserAuth, (state) => {
      state.status = "init";
      state.messages = {};
    });
  },
});

export const {
  messageReceived,
  addMessage,
  updateMessageStatus,
  closeConversation,
  startConversation,
  clearConversation,
  openedInAnotherTab,
  setDefaultKeyword,
} = conversationSlice.actions;

const persistConversationConfig = {
  key: "conversation",
  storage: storageSession,
  whitelist: ["messages", "status", "defaultKeyword"],
  version: 3,
  transforms: [openedInAnotherTabTransformer],
};

const persistedConversationReducer = persistReducer(
  persistConversationConfig,
  conversationSlice.reducer
);

export default persistedConversationReducer;
