import { Typography } from "@material-ui/core";
import useStyle from "./styles";
import { useAnalyticsLog } from "app/utils/hooks/useAnalyticsLog";
import useClearConversation from "app/utils/hooks/useClearConversation";
import { clearConversation } from "app/redux/slices/conversationSlice";
import { useAppDispatch } from "app/store";
import { Link } from "react-router-dom";
import AppNamedRoutes from "AppNamedRoutes";
import {
  selectIsIframeUI,
  selectPartnerSettings,
  selectUserPartner,
} from "app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";
import { PARTNER_CTL_WEBCHAT } from "../../app/redux/slices/authSlice";
import CtlWebchat from "./ClosedConvoBody/CtlWebchat";
import { events } from "../../app/utils/analytics/events";

const ClosedConvoBodyIframe = () => {
  const classes = useStyle();
  const setting = useSelector(selectPartnerSettings);

  return (
    <Typography variant="body1" className={classes.closedConvoText}>
      {setting.ui.closeConvoPreText} {setting.misc.phoneNumber}{" "}
      {setting.ui.closeConvoPostText}
    </Typography>
  );
};

const ClosedConvoBodyCTL = () => {
  const dispatch = useAppDispatch();
  const classes = useStyle();
  const setting = useSelector(selectPartnerSettings);

  return (
    <div>
      <Typography variant="body1" className={classes.closedConvoText}>
        For your privacy, we'll take you to our homepage in ten minutes, or you
        can go to the{" "}
      </Typography>
      <Link
        className={classes.closedConvoTextLink}
        to={setting.misc.customHomepageRoute ?? AppNamedRoutes.home}
        aria-label="homepage"
        onClick={() => {
          dispatch(clearConversation());
        }}
      >
        homepage
      </Link>
      <Typography variant="body1" className={classes.closedConvoText}>
        {" "}
        now.
      </Typography>
      <Typography variant="body1" className={classes.closedConvoText}>
        {" "}
        Remember, if you clear your browser history, you'll need to go back
        through Google Classroom to access us again.
      </Typography>
    </div>
  );
};

export const ClosedConversation = () => {
  const settings = useSelector(selectPartnerSettings);
  const classes = useStyle();
  const isIframeUI = useSelector(selectIsIframeUI);
  const partner = useSelector(selectUserPartner);
  const endConvoTitle = settings.ui.yourConversationHasBeenClosed;

  useAnalyticsLog(events.navigation.chatClosed);

  useClearConversation();

  return (
    <div className={classes.closedConversation}>
      <Typography variant="h1" className={classes.closedConvoHeader}>
        {endConvoTitle}
      </Typography>
      {isIframeUI ? (
        <ClosedConvoBodyIframe />
      ) : partner === PARTNER_CTL_WEBCHAT ? (
        <CtlWebchat />
      ) : (
        <ClosedConvoBodyCTL />
      )}
    </div>
  );
};
