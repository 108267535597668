import GENERAL_SETTINGS_DATA from "./generalSettings.json";

export type AcceptTermsType = {
  url: string;
  ariaLabel: string;
  linkText: string;
  textBefore: string;
};

export type GeneralSetting = {
  apiUrl: string;
  socketUrl: string;
  amplitudeApiKey: string;
  enableDebuggingFeatures: boolean;
  sentryDns: string;
  recaptchaPublicKey: string;
  chatBoxMaxLength: number;
  acceptTerms?: AcceptTermsType;
  footerLink: {
    url: string;
    ariaLabel: string;
    linkText: string;
    textBefore: string;
  };
  letsChatButtonText: string;
  welcomeText: string;
};

export const generalSettings: GeneralSetting = {
  ...GENERAL_SETTINGS_DATA,
  // Trim trailing / from URLs if present, since the code adds it when needed.
  apiUrl: process.env.REACT_APP_API_URL
    ? (process.env.REACT_APP_API_URL as string).replace(/[/]$/, "")
    : "",
  socketUrl: process.env.REACT_APP_SOCKET_URL
    ? (process.env.REACT_APP_SOCKET_URL as string).replace(/[/]$/, "")
    : "",
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY as string,
  enableDebuggingFeatures: Boolean(
    process.env.REACT_APP_ENABLE_DEBUGGING_FEATURES === "true"
  ),
  sentryDns: process.env.REACT_APP_SENTRY_DNS as string,
  recaptchaPublicKey: process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY as string,
  chatBoxMaxLength: 300,
};
