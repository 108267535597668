import { Container } from "@material-ui/core";
import useStyles from "./styles";
import ctlLogoSVG from "pages/ctlLogoSVG.svg";
import { useAnalyticsLog } from "app/utils/hooks/useAnalyticsLog";
import { events } from "../../app/utils/analytics/events";

type SplashProps = {
  showCTLLogo: boolean;
};

function Splash({ showCTLLogo = false }: SplashProps) {
  useAnalyticsLog(events.navigation.splash);

  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <div className={`${classes.breathingOrb} ${classes.orb}`} />
      <div className={classes.loading}>
        {showCTLLogo && <img src={ctlLogoSVG} alt="Crisis Text Line Logo" />}
        <p>Loading...</p>
      </div>
    </Container>
  );
}

export default Splash;
