import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsSoundOn } from "app/redux/selectors/menuSelector";
import GreyTooltip from "components/StyledMaterialUI/CustomizedTooltip";
const MenuIconWithTooltip = ({ children }: { children: JSX.Element }) => {
  const [displayMenuTip, setDisplayMenuTip] = useState(false);
  useEffect(() => {
    setDisplayMenuTip(true);
  }, []);
  const handleClose = () => {
    setDisplayMenuTip(false);
  };
  const handleOpen = () => {
    setDisplayMenuTip(true);
  };
  const soundOn = useSelector(selectIsSoundOn);
  return (
    <GreyTooltip
      open={displayMenuTip}
      onClose={handleClose}
      onOpen={handleOpen}
      enterTouchDelay={0}
      title={`Sound is currently ${
        soundOn ? "on" : "off"
      }. Adjust your sound preferences in the menu.`}
      arrow
    >
      <span>{children}</span>
    </GreyTooltip>
  );
};
export default MenuIconWithTooltip;
