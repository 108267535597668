import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/store";
import { sendMessage } from "app/redux/actions/conversation";
import { Status } from "app/redux/slices/conversationSlice";
import {
  selectConversationStatus,
  selectIsIframeUI,
  selectPartnerSettings,
} from "app/redux/selectors/conversationSelector";
import { selectConnected } from "app/redux/selectors/connectionSelector";
import useStyle from "./styles";
import Conversation from "components/Conversation";
import MainContainer from "components/Layout/MainContainer";
import { ClosedConversation } from "./ClosedConversation";
import ChatBox from "./ChatBox";
import Dialogs from "./Dialogs";
import ErrorContainer from "../Errors/ErrorContainer";
import Analytics from "app/utils/analytics";
import Menu from "../Layout/Menu";
import { useTokenAuthentication } from "app/utils/hooks/useTokenAuthenticator";
import useChangeTitle from "app/utils/hooks/useChangeTitle";
import { selectIdleState } from "../../app/redux/selectors/idleSelector";
import { usePageVisibility } from "react-page-visibility";
import useOnWindowClosed from "app/utils/hooks/useOnWindowClosed";
import { events } from "../../app/utils/analytics/events";
import generalSettings from "../../app/utils/settings";

const analyticsMap: { [key in Status]?: string } = {
  ongoing: events.navigation.chatOngoing,
  closed: events.navigation.chatClosed,
  openedInAnotherTab: events.errorPages.openedInAnotherTab,
};

export default function Chat() {
  const dispatch = useAppDispatch();
  useTokenAuthentication();

  const [isEndConversationDialogOpen, setEndConversationDialogOpen] =
    useState<boolean>(false);
  const isPageVisible = usePageVisibility();
  const ConvoStatus = useSelector(selectConversationStatus);
  const isConnected = useSelector(selectConnected);
  const idleState = useSelector(selectIdleState);
  const isIframeUI = useSelector(selectIsIframeUI);
  const settings = useSelector(selectPartnerSettings);

  const classes = useStyle();

  useChangeTitle("Conversation with Crisis Text Line crisis counselor");

  useOnWindowClosed();

  useEffect(() => {
    if (analyticsMap[ConvoStatus] !== undefined) {
      Analytics.log(analyticsMap[ConvoStatus] as string);
    }
  }, [ConvoStatus]);

  useEffect(() => {
    if (isPageVisible) {
      Analytics.log(events.navigation.pageComingBack);
    } else {
      Analytics.log(events.navigation.pageGoingAway);
    }
  }, [isPageVisible]);

  if (ConvoStatus === "openedInAnotherTab") {
    return <ErrorContainer error="openedInAnotherTab" header={!isIframeUI} />;
  }

  return (
    <MainContainer header={!isIframeUI} displaySoundTooltip>
      <Conversation />
      <hr
        className={classes.hr}
        style={{ display: ConvoStatus === "ongoing" ? "inline" : "none" }}
      />
      <>
        {ConvoStatus === "ongoing" && (
          <div className={classes.clearPadding}>
            <ChatBox
              minLength={0}
              maxLength={generalSettings.chatBoxMaxLength}
              label={settings.ui.chatboxLabelText}
              onSendMessageClick={(message) => {
                dispatch(sendMessage({ message }));
                Analytics.log(events.userActions.sendMessage);
              }}
              onStopClick={() => {
                setEndConversationDialogOpen(true);
              }}
              isConnected={isConnected}
            />
          </div>
        )}
      </>
      <>{ConvoStatus === "closed" && <ClosedConversation />}</>
      <Dialogs
        showTimeOut={
          idleState === "idleSoon" && settings.idleMessageStyle === "popup"
        }
        showEndConversation={isEndConversationDialogOpen}
        onEndConversationClose={() => {
          setEndConversationDialogOpen(false);
        }}
      />
      <Menu />
    </MainContainer>
  );
}
