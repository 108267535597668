import { createTheme } from "@material-ui/core/styles";

const ctlRed = "#e40b14";
const ctlWhite = "#e7e7e7";
const ctlDarkGrey = "#444444";
const ctlBlue = "#314766";
const ctlLightBlue = "#a7dadc";

const green988 = "#6FCBB7";
const lightGreen988 = "#3FCFB5";
const red988 = "#DF3544";
const blue988 = "#417AE0";
const lightBlue988 = "#D1E3FC";
const darkBlue988 = "#174392";
const darkerBlue988 = "#054085";
const grey988 = "#EEEEEE";
const yellow988 = "#FFF3CC";
const brown988 = "#856305";

const theme = createTheme({
  palette: {
    ctlColors: {
      red: `${ctlRed}`,
      white: `${ctlWhite}`,
      darkGrey: `${ctlDarkGrey}`,
      lightBlue: `${ctlLightBlue}`,
    },
    colors988: {
      green: `${green988}`,
      lightGreen: `${lightGreen988}`,
      red: `${red988}`,
      blue: `${blue988}`,
      lightBlue: `${lightBlue988}`,
      darkBlue: `${darkBlue988}`,
      darkerBlue: `${darkerBlue988}`,
      brown: `${brown988}`,
      grey: `${grey988}`,
      yellow: `${yellow988}`,
    },
    primary: {
      main: `${ctlBlue}`,
    },
    error: {
      main: `${ctlRed}`,
    },
    grey: {
      100: "#fcfcfc",
      200: "#f8f8f8",
      300: "#eee",
      400: "#ccc",
      500: "#707070",
      600: "#343434",
    },
  },
  typography: {
    fontFamily: ["Public Sans", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontFamily: "Public Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "1.375rem",
      lineHeight: "1.625rem",
      textAlign: "center",
    },
    h2: {
      fontSize: "1.375rem",
      fontWeight: "normal",
      textAlign: "center",
      lineHeight: "1.625rem",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300,
      lineHeight: "1.188rem",
      textAlign: "center",
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 300,
      lineHeight: "1.188rem",
      textAlign: "left",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 350,
      md: 600,
      lg: 840,
      xl: 1280,
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: "25px",
      textTransform: "none",
    },
    containedPrimary: {
      color: `${theme.palette.common.white}`,
      textAlign: "center",
      backgroundColor: `${theme.palette.grey[600]}`,
      fontWeight: 600,
      boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.2) `,
      "&:disabled": {
        color: `${theme.palette.common.white}`,
        backgroundColor: `${theme.palette.grey[400]}`,
      },
    },
    containedSecondary: {
      minWidth: "165px",
      width: "9em",
      backgroundColor: theme.palette.colors988.blue,
      color: `${theme.palette.common.white}`,
      textAlign: "center",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: theme.palette.colors988.darkBlue,
      },
      boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.2) `,
      "&:disabled": {
        color: `${theme.palette.common.white}`,
        backgroundColor: `${theme.palette.grey[400]}`,
      },
    },
    textSecondary: {
      color: `${theme.palette.grey[600]}`,
      fontWeight: 600,
      textAlign: "center",
      textDecoration: "underline",
      lineHeight: "1.188rem",
    },
  },
  MuiInputLabel: {
    root: {
      color: `${theme.palette.grey[600]}`,
    },
  },
};

export default theme;
