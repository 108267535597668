import { loadSound } from "app/utils/sound";
import { doConnect } from "app/redux/actions/sockets";
import { startConversation } from "app/redux/slices/conversationSlice";
import { emitSocketAction } from "app/redux/actions/conversation";
import Analytics from "app/utils/analytics";
import { useAppDispatch } from "app/store";
import { useSelector } from "react-redux";
import {
  selectDefaultKeyword,
  selectPartnerSettings,
  selectUser,
} from "app/redux/selectors/conversationSelector";
import { events } from "../analytics/events";
import { User } from "app/redux/slices/authSlice";

const useInitConversation = () => {
  const dispatch = useAppDispatch();
  const user: User = useSelector(selectUser);
  const settings = useSelector(selectPartnerSettings);
  const userProvidedKeyword = useSelector(selectDefaultKeyword);

  return () => {
    loadSound("internal");
    loadSound("default");

    dispatch(doConnect({ user }));
    dispatch(startConversation());

    dispatch(
      emitSocketAction({
        action: "setKeyword",
        data: {
          identity: user.identity,
          lang: user.lang,
          keyword:
            userProvidedKeyword === false
              ? settings.misc.keyword
              : userProvidedKeyword,
        },
      })
    );

    Analytics.log(events.userActions.talkToACounselor);
  };
};

export default useInitConversation;
