import { combineSagas } from "app/utils/sagaUtils";
import socketConnection from "app/redux/sagas/socketConnection";
import socketDisconnection from "app/redux/sagas/socketDisconnection";
import incomingEvents from "app/redux/sagas/incomingEvents";
import outgoingEvents from "app/redux/sagas/outgoingEvents";
import messageFailure from "app/redux/sagas/messageFailure";
import longTermDisconnect from "app/redux/sagas/longTermDisconnect";
import soundSaga from "app/redux/sagas/soundSaga";
import devSaga from "app/redux/sagas/devSaga";
import idleTimeoutSaga from "app/redux/sagas/idleTimeout";

export default combineSagas([
  socketConnection,
  socketDisconnection,
  incomingEvents,
  outgoingEvents,
  messageFailure,
  longTermDisconnect,
  soundSaga,
  devSaga,
  idleTimeoutSaga,
]);
