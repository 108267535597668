import useStyles from "./styles";
import type { Message as MessageType } from "app/redux/slices/conversationSlice";
import MessageStatus from "./MessageStatus";
import MessageTime from "./MessageTime";

export default function MessageInfo(message: MessageType) {
  const classes = useStyles();
  return (
    <span className={classes.time}>
      <MessageStatus {...message} />

      {!["sending", "failed"].includes(message.status || "") && (
        <MessageTime time={message.sendAt} />
      )}
    </span>
  );
}
