import React, { ReactElement } from "react";
import { AppBar, Toolbar, useScrollTrigger } from "@material-ui/core";
import useStyles from "./styles";

interface Props {
  children: ReactElement | ReactElement[];
}

function ElevationScroll(props: { children: ReactElement }) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Header: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <ElevationScroll>
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            {children}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default Header;
