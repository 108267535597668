import type {
  ActionHandler,
  SocketEventHandlerMap,
} from "app/sharedTypes/socketActionTypes";
import { put, takeEvery } from "redux-saga/effects";
import { onMessage } from "app/redux/actions/sockets";

export function* on(actionMap: Partial<SocketEventHandlerMap>) {
  yield takeEvery(onMessage, function* (onMessageAction) {
    const { action, data } = onMessageAction.payload;
    const actionHandler = actionMap[action] as ActionHandler;
    if (actionMap.hasOwnProperty(action)) {
      yield put(actionHandler(data));
    }
  });
}
