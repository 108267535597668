import { makeStyles, Theme } from "@material-ui/core/styles";

type containerType = (theme: Theme) => {
  background: any;
  border: any;
  display: any;
  flexDirection: any;
  flexGrow: any;
  justifyContent: any;
  paddingLeft: any;
  paddingRight: any;
  width: any;
};

const container: containerType = (theme) => {
  return {
    background: `${theme.palette.grey[100]}`,
    border: `1px solid ${theme.palette.ctlColors.white}`,
    display: "flex !important",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between",
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    width: "92%",
  };
};

export default makeStyles((theme) => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "100vh",
  },
  appWrapper: {
    background: `${theme.palette.grey[100]}`,
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
  chatContainer: {
    ...container(theme),
    textAlign: "center",
    height: "100vh",
    maxHeight: "100vh",
  },
  chatContaineMaxWidth: {
    maxWidth: theme.breakpoints.values["md"],
  },
  roundedContainer: {
    ...container(theme),
    borderRadius: "14px",
    height: "80%",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexGrow: 0,
  },
  contentWrap: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  errorContainer: {
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    maxHeight: "100vh",
  },
  introContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  footer: {
    background: `${theme.palette.grey[300]}`,
    bottom: 0,
    minHeight: "7vh",
    height: "3.6rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
  },
  title: {
    color: `${theme.palette.grey[600]}`,
    fontFamily: "Public Sans, serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "1.188rem",
    textAlign: "center",
  },
  appBar: {
    background: `${theme.palette.common.white}`,
    borderBottom: `1px solid ${theme.palette.ctlColors.white}`,
  },
  connectionStatus: {
    margin: theme.spacing(1),
  },
  logo: {
    marginTop: "7vh",
    marginBottom: "2vh",
    [theme.breakpoints.up("md")]: {
      marginTop: 103,
      marginBottom: 41,
    },
  },
  logoInHeader: {
    height: "50px",
  },
  appBarHeader: {
    alignItems: "center",
    background: `${theme.palette.common.white}`,
  },
  menuIcon: {
    color: `${theme.palette.ctlColors.darkGrey}`,
  },
  menuIconText: {
    fontWeight: 600,
    marginRight: "5px",
    color: `${theme.palette.ctlColors.darkGrey}`,
  },
  menu: {
    width: "30%",
    minWidth: "250px",
    flexShrink: 0,
  },
  menuPaper: {
    width: "30%",
    minWidth: "250px",
  },
  menuHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    height: "64px",
    width: "100%",
  },
  menuHeaderTitle: {
    fontWeight: 600,
    color: theme.palette.ctlColors.darkGrey,
    textAlign: "left",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    color: theme.palette.ctlColors.darkGrey,
    textDecoration: "underline",
  },
  hideMenu: {
    display: "none",
  },
  closeMenuButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  listItemText: {
    flex: "initial",
    padding: "0 10px 0 0",
  },
  termsAndConditionsText: {
    fontWeight: 300,
    fontStyle: "normal",
    lineHeight: "1.05rem",
    textAlign: "left",
    color: `${[theme.palette.ctlColors.darkGrey]}`,
    padding: "0px 0px 0px 2px",
  },
  readMoreOnSiteText: {
    fontWeight: 300,
    fontStyle: "normal",
    textAlign: "center",
    color: `${[theme.palette.ctlColors.darkGrey]}`,
    padding: "0px 0px 0.1875rem 0.125rem",
  },
  tosInFooter: {
    fontWeight: 300,
    fontStyle: "normal",
    textAlign: "left",
    color: `${[theme.palette.ctlColors.darkGrey]}`,
    padding: "0rem 0rem 0.5rem 0.125rem",
    display: "block",
  },
  menuSpacerContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  footerInfoInMenu: {
    marginBottom: "0.5rem",
    marginLeft: "20px",
    width: "90%",
  },
  supportText: {
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
  },
  addMarginBelowHalf: {
    marginBottom: "0.5rem",
  },
  addMarginBelowOne: {
    marginBottom: "1rem",
  },
  addMarginBelowOneAndHalf: {
    marginBottom: "1.5rem",
  },
  footerLinkInMenu: {
    width: "200px",
  },
  menuExtraInfo: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignSelf: "center",
  },
  liMargin: {
    marginBottom: "10px",
  },
  menuExpect: {
    fontWeight: 300,
    marginBottom: ".5em",
    fontSize: "1em",
  },
  textUs: {
    height: "100%",
    width: "90%",
    margin: "20px",
  },
  soundBoxOne: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  soundBoxTwo: {
    display: "flex",
    alignItems: "center",
  },
  notificationSoundText: {
    fontWeight: 300,
    marginBottom: ".5em",
    fontSize: "1em",
    [theme.breakpoints.down(1050)]: {
      width: "3.125rem",
      verticalAlign: "bottom",
    },
  },
  notificationInfoIcon: {
    color: "#757575",
    margin: "2px 30px 2px 2px",
    verticalAlign: "bottom",
    height: "0.72em",
    width: "0.72em",
    "&:hover": {
      color: `${theme.palette.ctlColors.darkGrey}`,
    },
  },
  iconAlignment: {
    verticalAlign: "bottom",
    minWidth: "20px",
  },
  whiteSpace: {
    whiteSpace: "nowrap",
  },
}));
