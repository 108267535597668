import { Button, FormControl } from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import theme from "app/utils/theme";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import type { StopButtonVariants } from "app/utils/partnerSettings/defaults";

type StopButtonProps = {
  onStopClick: () => void;
  variant: StopButtonVariants;
};

export default function StopButton({
  variant = "complex",
  onStopClick,
}: StopButtonProps) {
  const classes = useStyles();
  const settings = useSelector(selectPartnerSettings);

  const buttonText = settings.ui.stopButtonText[variant];
  const buttonVariant = variant === "simple" ? "contained" : "text";
  const buttonColor = variant === "simple" ? "primary" : undefined;
  const buttonBackgroundColor =
    variant === "simple" ? theme.palette.colors988.red : "transparent";
  const className = variant === "simple" ? undefined : classes.stopButton;
  const classText = variant === "simple" ? undefined : classes.conversationText;

  return (
    <FormControl variant="outlined">
      <Button
        component={"a"}
        variant={buttonVariant}
        color={buttonColor}
        onClick={onStopClick}
        className={className}
        aria-label={buttonText}
        style={{ backgroundColor: buttonBackgroundColor }}
      >
        <span className={classText}>{buttonText}</span>
      </Button>
    </FormControl>
  );
}
