import { useSelector } from "react-redux";
import Chat from "components/Chat";
import {
  selectConversationStatus,
  selectIsIframeUI,
  selectPartnerSettings,
} from "app/redux/selectors/conversationSelector";
import NewConversation from "components/Intro/NewConversation";
import { useOnlyAuthenticated } from "app/utils/hooks/useTokenAuthenticator";
import type { Status } from "app/redux/slices/conversationSlice";
import NewConvoIframe from "../components/Intro/NewConvoIframe";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const conversationStatus = useSelector(selectConversationStatus);
  const se = useSelector(selectPartnerSettings);
  const nav = useNavigate();
  useOnlyAuthenticated();
  useEffect(() => {
    if (se.misc.customHomepageRoute && conversationStatus === "init") {
      nav(se.misc.customHomepageRoute);
    }
  });

  return (
    <div className="App" style={{ textAlign: "center" }}>
      <RenderHomeOrAbout />
    </div>
  );
}

const shouldRenderChat = (conversationStatus: Status) => {
  return ["ongoing", "closed", "openedInAnotherTab"].includes(
    conversationStatus
  );
};

function RenderHomeOrAbout() {
  const conversationStatus = useSelector(selectConversationStatus);
  const isIframeUI = useSelector(selectIsIframeUI);
  const renderChat = shouldRenderChat(conversationStatus);

  if (renderChat) {
    return <Chat />;
  }

  if (isIframeUI) {
    return <NewConvoIframe />;
  }

  return <NewConversation />;
}

export default Home;
