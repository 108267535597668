import type { LanguageCode, Partner } from "../redux/slices/authSlice";
import {
  PARTNER_988,
  PARTNER_CTL_WEBCHAT,
  PARTNER_GOOGLE,
} from "../redux/slices/authSlice";
import type { PartnerSetting } from "./partnerSettings/defaults";
import SETTINGS_CLASSROOM from "./partnerSettings/google";
import SETTINGS_988, { SETTINGS_988_ES } from "./partnerSettings/lifeLine988";
import SETTINGS_CTL from "./partnerSettings/ctlWebchat";
import { GeneralSetting, generalSettings } from "./generalSettings";

export type Settings = PartnerSetting & GeneralSetting;

export const getSettingsForPartner = (
  partner: Partner,
  language: LanguageCode
): PartnerSetting => {
  switch (partner.toUpperCase() as Partner) {
    case PARTNER_988: {
      switch (language) {
        case "es":
          return SETTINGS_988_ES;
        default:
          return SETTINGS_988;
      }
    }
    case PARTNER_GOOGLE: {
      return SETTINGS_CLASSROOM;
    }
    case PARTNER_CTL_WEBCHAT: {
      return SETTINGS_CTL;
    }
  }
};

// re-exporting this to prevent having to update a bunch of imports. I will
// add a separate PR to fix this.
export default generalSettings;
