import React from "react";
import Drawer from "@material-ui/core/Drawer";
import useStyles from "./styles";
import { Button, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { selectIsMenuOpen } from "app/redux/selectors/menuSelector";
import { useSelector } from "react-redux";
import { closeMenu } from "app/redux/slices/menuSlice";
import { useAppDispatch } from "app/store";
import MenuListItemText from "components/StyledMaterialUI/MenuListItemText";
import ConnectionBadge from "../StyledMaterialUI/ConnectionBadge";
import { selectConnected } from "app/redux/selectors/connectionSelector";
import InfoIcon from "@material-ui/icons/Info";
import CustomizedSwitch from "components/Menu/CustomizedSwitch";
import GreyTooltip from "components/StyledMaterialUI/CustomizedTooltip";
import OpenInNewTabIconHelper from "components/StyledMaterialUI/OpenInNewTabHelper";

const Menu = () => {
  const dispatch = useAppDispatch();

  const handleMenuClose = () => {
    dispatch(closeMenu());
  };

  const checkIsMenuOpen = useSelector(selectIsMenuOpen);
  const isConnected = useSelector(selectConnected);

  const classes = useStyles();

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={checkIsMenuOpen}
      className={classes.menu}
      classes={{ paper: classes.menuPaper }}
      data-testid="menu"
    >
      <div className={classes.menuHeader}>
        <Typography className={classes.menuHeaderTitle}> Menu </Typography>
        <IconButton
          onClick={handleMenuClose}
          className={classes.closeMenuButton}
          aria-label="close menu"
          style={{ backgroundColor: "transparent" }}
        >
          <ClearIcon className={classes.menuIcon} />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem>
          <div className={classes.soundBoxOne}>
            <div className={classes.soundBoxTwo}>
              <h3 className={classes.notificationSoundText}>
                Notification{" "}
                <span className={classes.whiteSpace}>
                  sounds{" "}
                  <GreyTooltip
                    title="When sound is ON and you are away from this page, we will play a sound to notify you of a new message from your Crisis Counselor."
                    arrow
                  >
                    <InfoIcon className={classes.notificationInfoIcon} />
                  </GreyTooltip>
                </span>
              </h3>
            </div>
            <CustomizedSwitch />
          </div>
        </ListItem>
      </List>
      <List>
        <ListItem
          role="link"
          component="a"
          href="https://www.crisistextline.org"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuItem}
        >
          <MenuListItemText
            aria-label="More info about Crisis Text Line (opens in new tab)"
            primaryTypographyProps={{ variant: "body2" }}
            primary={
              <>
                More info about Crisis Text{" "}
                <span className={classes.whiteSpace}>
                  Line{" "}
                  <ListItemIcon className={classes.iconAlignment}>
                    <OpenInNewTabIconHelper />
                  </ListItemIcon>
                </span>
              </>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          role="link"
          component="a"
          href="https://www.crisistextline.org/about-us/faq/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuItem}
        >
          <MenuListItemText
            aria-label="FAQs about service (opens in new tab)"
            primaryTypographyProps={{ variant: "body2" }}
            primary={
              <>
                FAQs about{" "}
                <span className={classes.whiteSpace}>
                  service
                  <ListItemIcon className={classes.iconAlignment}>
                    <OpenInNewTabIconHelper />
                  </ListItemIcon>
                </span>
              </>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <div className={classes.menuExtraInfo}>
        <h3 className={classes.menuExpect}>
          What to expect in this conversation
        </h3>
        <ul>
          <li className={classes.liMargin}>
            Conversations usually last 15-45 minutes.
          </li>
          <li className={classes.liMargin}>
            If we don't hear from you for 10 minutes or more, we may close your
            conversation to protect your privacy.
          </li>
          <li className={classes.liMargin}>
            Links shared during your conversation will open in a new tab.
          </li>
        </ul>
      </div>
      <Divider />
      <div className={classes.menuSpacerContainer}>
        <div className={classes.footerInfoInMenu}>
          <Typography variant="body2" className={classes.supportText}>
            Support is available by phone for everyone in the US.
          </Typography>
          <Typography
            variant="body2"
            className={classes.addMarginBelowOneAndHalf}
          >
            Text STUDENT to 741741
          </Typography>
          <div className={classes.footerLinkInMenu}>
            <Button
              role="link"
              variant="text"
              color="secondary"
              href="https://www.crisistextline.org/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Crisis Text Line's Terms of Service and Privacy Policy (opens in a new tab)"
              className={classes.tosInFooter}
              style={{ backgroundColor: "transparent" }}
            >
              Terms of Service & Privacy{" "}
              <span className={classes.whiteSpace}>
                Policy
                <OpenInNewTabIconHelper />
              </span>
            </Button>
          </div>
          <Typography variant="body2" className={classes.addMarginBelowHalf}>
            <ConnectionBadge
              color={isConnected ? "primary" : "error"}
              variant="dot"
              className={`${classes.connectionStatus}`}
            />
            {process.env.REACT_APP_CURRENT_GIT_TAG || "undetermined"}
          </Typography>
          <Typography variant="body2" className={classes.addMarginBelowOne}>
            © Crisis Text Line 2013–2021
          </Typography>
        </div>
      </div>
    </Drawer>
  );
};

export default Menu;
