import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import connectionSlice from "./connectionSlice";
import conversationSlice from "./conversationSlice";
import idleSlice from "./IdleSlice";
import menuSlice from "./menuSlice";
import authSlice from "./authSlice";
import { RouterState } from "redux-first-history";

const createRootReducer = (routerReducer: Reducer<RouterState, AnyAction>) =>
  combineReducers({
    connection: connectionSlice,
    conversation: conversationSlice,
    auth: authSlice,
    idleSlice: idleSlice,
    menuSlice: menuSlice,
    //router: connectRouter(history),
    router: routerReducer,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export { createRootReducer };
