import { useEffect } from "react";
import AppNamedRoutes from "AppNamedRoutes";
import { doConnect } from "app/redux/actions/sockets";
import { useAppDispatch } from "app/store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectConversationStatus,
  selectUser,
} from "app/redux/selectors/conversationSelector";

export const useTokenAuthentication = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const conversationStatus = useSelector(selectConversationStatus);
  useEffect(() => {
    if (!["closed"].includes(conversationStatus)) {
      dispatch(doConnect({ user }));
    }
  }, [dispatch, conversationStatus, user]);
};

export const useOnlyAuthenticated = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user.identity === "") {
      navigate(AppNamedRoutes.noToken);
    }
  }, [navigate, dispatch, user.identity]);
};
