import { SocketCloseCodes, SocketHelper } from "app/utils/socketHelper";
import type { ActionPattern } from "redux-saga/effects";
import {
  actionChannel,
  call,
  cancel,
  delay,
  put,
  race,
  take,
  takeLeading,
} from "redux-saga/effects";
import {
  closeConnection,
  doConnect,
  onClose,
  onError,
  onMessage,
  onOpen,
} from "app/redux/actions/sockets";
import { forkSocketHandlers } from "app/utils/sagaUtils";
import { emitSocketAction } from "app/redux/actions/conversation";
import {
  clearConversation,
  closeConversation,
} from "../slices/conversationSlice";
import { secToMs } from "app/utils/converter";
import generalSettings from "app/utils/settings";
import { SagaEffect } from "app/sharedTypes/SagaTypeHelper";
import type { Task } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import { SocketEventDataMap } from "app/sharedTypes/socketActionTypes";

function* handleConnections(): SagaEffect<ActionPattern> {
  let emitSocketActionChannel = yield actionChannel(emitSocketAction);

  function* outgoingMessageHandler(
    socket: WebSocket
  ): SagaEffect<PayloadAction> {
    while (true) {
      const action = yield take(emitSocketActionChannel);
      if (socket.readyState === 1) {
        let payload = "";
        if (typeof action.payload === "string") {
          payload = action.payload;
        } else {
          payload = JSON.stringify(action.payload);
        }
        socket.send(payload);
      } else {
        yield delay(1000);
        yield put(action);
      }
    }
  }

  function* gracefulCloseHandler(socket: WebSocket) {
    while (true) {
      yield take(closeConversation);
      yield delay(secToMs(5));
      socket.close(SocketCloseCodes.CLOSE_APP_NORMAL);
    }
  }

  function* forceCloseHandler(socket: WebSocket) {
    while (true) {
      yield take(clearConversation);
      socket.close(SocketCloseCodes.CLOSE_APP_NORMAL);
    }
  }

  function* handleCloseConnection(
    socket: WebSocket
  ): SagaEffect<PayloadAction<SocketEventDataMap["closeConnection"]>> {
    while (true) {
      const action = yield take(closeConnection);
      socket.close(action.payload.code);
    }
  }

  yield takeLeading(doConnect, function* (connectAction): SagaEffect<Task> {
    const {
      user: { identity },
      reconnecting = false,
    } = connectAction.payload;
    let socket = SocketHelper.connect(identity);

    if (generalSettings.enableDebuggingFeatures) {
      window.ctlSocket = socket;
    }

    const handlerTask = yield forkSocketHandlers(
      {
        open: () => onOpen({ reconnecting: reconnecting }),
        close: onClose,
        error: onError,
        message: onMessage,
      },
      socket
    );

    yield race({
      _: call(outgoingMessageHandler, socket),
      // when we receive close conversation gracefully close the connection
      graceful: call(gracefulCloseHandler, socket),
      // when user go to home page and fire clear conversation we close the connection if it was still open
      force: call(forceCloseHandler, socket),
      // if server sent close connection explicitly we do so here
      serverClose: call(handleCloseConnection, socket),
      // whenever any of the above closes the connection or for any other reason the connection is closed this is called
      // and is considered the end of the race effect above
      close: take(onClose),
    });

    yield cancel(handlerTask);
  });
}

export default handleConnections;
