import { eventChannel } from "redux-saga";
import { ActionCreator, PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import { emitSocketAction } from "app/redux/actions/conversation";

type SagaEventHandlerMap = {
  [eventName: string]: ActionCreator<any>;
};
const createEventChannel = (
  handlersMap: SagaEventHandlerMap,
  socket: WebSocket
) =>
  eventChannel<PayloadAction>((dispatch) => {
    let wrappedHandlers: {
      [eventName: string]: (...args: Array<any>) => void;
    } = {};

    for (const [event, action] of Object.entries(handlersMap)) {
      wrappedHandlers[event] = (...args) => {
        args = args.map((arg) => {
          switch (arg.constructor.name) {
            case "Event": {
              arg = {
                type: arg.type,
              };
              break;
            }
            case "CloseEvent": {
              arg = {
                type: "close",
                code: arg.code,
                reason: arg.reason,
                wasClean: arg.wasClean,
              };
              break;
            }
            case "MessageEvent": {
              if (arg.data === "ping") {
                //@ts-ignore we just want to return pong
                dispatch(emitSocketAction("pong"));
              } else {
                try {
                  arg = { ...JSON.parse(arg.data) };
                  // use during development on echo server.
                } catch (error) {
                  console.debug(
                    "we just don't accept none json responses",
                    error
                  );
                  arg = arg.data;
                }
              }
            }
          }
          return { ...arg };
        });
        dispatch(action(...args));
      };
      socket.addEventListener(event, wrappedHandlers[event]);
    }

    return () => {
      for (const [event, wrappedHandler] of Object.entries(wrappedHandlers)) {
        socket.removeEventListener(event, wrappedHandler);
      }
    };
  });
export const forkSocketHandlers = (
  handlersMap: SagaEventHandlerMap,
  socket: WebSocket
) => {
  const channel = createEventChannel(handlersMap, socket);

  return takeEvery(channel, function* dispatchAction(action) {
    yield put(action);
  });
};
