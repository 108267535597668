import { Container } from "@material-ui/core";
import Header from "./Header";
import ctlLogoSVG from "pages/MAIN_WEBCHAT_LOGO.svg";
import useStyles from "./styles";
import MenuIconButton from "components/Menu/MenuIconButton";
import MenuIconWithTooltip from "components/Menu/IconWithToolTip";
import {
  selectIsIframeUI,
  selectPartnerSettings,
} from "app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";

type MainContainerProps = {
  children: Array<JSX.Element> | JSX.Element;
  title?: string;
  header?: boolean;
  logo?: boolean;
  type?: "chat" | "rounded" | "error" | "intro";
  titleComponents?: JSX.Element;
  displaySoundTooltip?: boolean;
  containerShowMenu?: boolean;
};

const MainContainer = ({
  children,
  header = false,
  type = "chat",
  displaySoundTooltip = false,
  containerShowMenu = true,
}: MainContainerProps) => {
  const setting = useSelector(selectPartnerSettings);
  const isIframeUI = useSelector(selectIsIframeUI);
  const classes = useStyles();

  const containerClassMap = {
    chat: isIframeUI
      ? classes.chatContainer
      : `${classes.chatContainer} ${classes.chatContaineMaxWidth}`,
    rounded: classes.roundedContainer,
    error: classes.errorContainer,
    intro: classes.introContainer,
  };

  return (
    <div className={classes.appWrapper}>
      <div className={classes.contentWrap}>
        <>
          {header && (
            <Header>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={ctlLogoSVG} alt="Crisis Text Line Logo" />
              </div>
              <>
                {containerShowMenu && setting.showMenu && (
                  <div>
                    {displaySoundTooltip && (
                      <MenuIconWithTooltip>
                        <MenuIconButton />
                      </MenuIconWithTooltip>
                    )}
                    {!displaySoundTooltip && <MenuIconButton />}
                  </div>
                )}
              </>
            </Header>
          )}
        </>
        <Container className={containerClassMap[type]} maxWidth={false}>
          {children}
        </Container>
      </div>
    </div>
  );
};

export default MainContainer;
