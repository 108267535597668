const AppNamedRoutes = {
  home: "/",
  iframe: "/iframe/:token",
  auth988: "/988/:token",
  auth: "/:token",
  partner: "/partner/:partner",
  chat: "/chat",
  noToken: "/please-get-another-link",
  disconnected: "/disconnected",
  linkExpired: "/link-expired",
};

export default AppNamedRoutes;
