import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Illustration from "pages/Illustration.svg";
import MainContainer from "components/Layout/MainContainer";
import useStyles from "./styles";
import { useAnalyticsLog } from "app/utils/hooks/useAnalyticsLog";
import MoreInfo from "./MoreInfo";
import Footer from "../Layout/Footer";
import Menu from "../Layout/Menu";
import MobileWarning from "../Dialogs/MobileWarning";
import { isMobile, isTablet } from "react-device-detect";
import useChangeTitle from "app/utils/hooks/useChangeTitle";
import OpenInNewTabIconHelper from "../StyledMaterialUI/OpenInNewTabHelper";
import useInitConversation from "app/utils/hooks/useInitConversation";
import { useSendInitialMessage } from "../../app/utils/hooks/useSendMessage";
import { events } from "../../app/utils/analytics/events";

function NewConversation() {
  const [userAgreesToTermsAndConditions, setUserAgreesToTermsAndConditions] =
    useState<boolean>(false);

  const isMobileAndNotTablet: boolean | string = isMobile && !isTablet;
  const [showWarning, setShowWarning] = useState<boolean>(
    !!isMobileAndNotTablet
  );

  const classes = useStyles();
  useAnalyticsLog(events.navigation.introNew);
  useChangeTitle(
    "Connect with a Crisis Text Line crisis counselor via an online text conversation"
  );
  const initConversation = useInitConversation();
  const sendInitialMessage = useSendInitialMessage();

  const handleStartConversation = () => {
    initConversation();
    sendInitialMessage();
  };

  return (
    <>
      <MainContainer header type={"intro"}>
        <section className={classes.about}>
          <div className={classes.newConvoInfo}>
            <img
              src={Illustration}
              alt="Chat Messages decorative example"
              className={classes.newConvoImg}
            />
            <div>
              <Typography variant="h1" className={classes.welcomeText}>
                If you need someone to talk to, support is here.
              </Typography>
            </div>
            <div style={{ paddingRight: "4px" }}>
              <Button
                disabled={!userAgreesToTermsAndConditions}
                variant="contained"
                color="primary"
                onClick={() => handleStartConversation()}
              >
                Start a conversation
              </Button>
              <FormControlLabel
                classes={{ label: classes.labelTypography }}
                className={classes.agreeToConditions}
                control={
                  <Checkbox
                    checked={userAgreesToTermsAndConditions}
                    color="primary"
                    inputProps={{
                      "aria-label": "I agree to the Terms and Conditions",
                    }}
                    onChange={() => {
                      setUserAgreesToTermsAndConditions(
                        !userAgreesToTermsAndConditions
                      );
                    }}
                  />
                }
                label={
                  <>
                    <Typography className={classes.agreeText}>
                      To start a conversation, check this box to agree to Crisis
                      Text Line's{" "}
                    </Typography>
                    <Button
                      aria-label="Terms of Service and Privacy Policy (opens in a new tab)"
                      className={classes.termsAndConditionsText}
                      role="link"
                      variant="text"
                      color="secondary"
                      href="https://www.crisistextline.org/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      Terms of Service & Privacy{" "}
                      <span className={classes.whiteSpace}>
                        Policy
                        <span>
                          <OpenInNewTabIconHelper />
                        </span>
                      </span>
                    </Button>
                    <Typography className={classes.agreeText}>
                      , including our use of cookies and similar features on
                      this website.
                    </Typography>
                  </>
                }
              />
            </div>
          </div>
        </section>
        <MoreInfo />
        <Menu />
        <Footer />
        <MobileWarning
          isOpen={showWarning}
          onAccept={() => {
            setShowWarning(false);
          }}
        />
      </MainContainer>
    </>
  );
}

export default NewConversation;
