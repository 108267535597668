import React from "react";
import { useAppDispatch } from "app/store";
import { active } from "app/redux/slices/IdleSlice";
import { sendMessage } from "app/redux/actions/conversation";
import CloseConversationDialog from "components/Dialogs/CloseConversationDialog";
import IdleTimeoutDialog from "components/Dialogs/IdleTimeout";
import { selectPartnerSettings } from "../../app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";

type DialogsProps = {
  showTimeOut: boolean;
  showEndConversation: boolean;
  onEndConversationClose: () => void;
};

const Dialogs = ({
  showTimeOut,
  showEndConversation,
  onEndConversationClose,
}: DialogsProps) => {
  const dispatch = useAppDispatch();
  const settings = useSelector(selectPartnerSettings);
  return (
    <>
      <CloseConversationDialog
        isOpen={showEndConversation}
        onClose={onEndConversationClose}
        onAccept={() => {
          dispatch(sendMessage({ message: "STOP", systemGenerated: true }));
          onEndConversationClose();
        }}
      />
      <IdleTimeoutDialog
        isOpen={showTimeOut}
        onClick={() => dispatch(active())}
        timeoutReminder={
          settings.timeouts.timeToTimeout - settings.timeouts.timeoutReminder
        }
      />
    </>
  );
};

export default Dialogs;
