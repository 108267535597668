import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  hr: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0, 1, 0),
    border: "0",
    borderTop: `1px solid ${theme.palette.ctlColors.white}`,
  },
  clearPadding: {
    padding: theme.spacing(2),
  },
  controls: {
    marginTop: "25px !important",
    padding: theme.spacing(1),
    [theme.breakpoints.between(360, 320)]: {
      marginRight: theme.spacing(1),
    },
  },
  send: {
    minWidth: "revert !important",
  },
  connectionErrorButton: {
    backgroundColor: `${theme.palette.ctlColors.red} !important`,
  },
  stopLink: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      paddingTop: "14px",
    },
  },
  composeContainer: {
    [`& fieldset`]: {
      borderRadius: "9px",
    },
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  compose: {
    background: theme.palette.common.white,
    borderRadius: "14px",
    boxSizing: "border-box",
  },
  stopButton: {
    textDecoration: "underline !important",
    textUnderlineOffset: "2px !important",
    "&:hover": {
      color: theme.palette.ctlColors.red,
    },
  },
  closedConversation: {
    margin: theme.spacing(0, 4, 4, 4),
    padding: theme.spacing(1, 2, 2, 2),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "14px",
  },
  closedConvoHeader: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 1, 2),
    },
  },
  connectionError: {
    padding: theme.spacing(1.1, 1, 0, 0),
    color: theme.palette.ctlColors.red,
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  sendButtonWrapper: {
    textAlign: "right",
    marginRight: "1rem !important",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0 !important",
      textAlign: "center",
    },
    [theme.breakpoints.between(351, 400)]: {
      marginRight: "1.5rem !important",
    },
  },
  conversationText: {
    [theme.breakpoints.down(510)]: {
      display: "none",
    },
  },
  closedConvoTextLink: {
    display: "inline",
    fontWeight: 300,
    lineHeight: "1.188rem",
    color: theme.palette.grey[600],
    "&:hover": {
      textDecoration: "none",
    },
  },
  closedConvoText: {
    display: "inline",
  },
}));
