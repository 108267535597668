import { combineSagas } from "app/utils/sagaUtils";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { sendMessage } from "app/redux/actions/conversation";
import { active, idle, idleSoon } from "../slices/IdleSlice";
import Analytics from "app/utils/analytics";
import { closeConnection, onOpen } from "../actions/sockets";
import { SocketCloseCodes } from "app/utils/socketHelper";
import { addMessage, closeConversation } from "../slices/conversationSlice";
import {
  selectPartnerSettings,
  selectUser,
} from "../selectors/conversationSelector";
import {
  generateMessage,
  generateIdleMessageTitle,
  generateIdleMessageText,
} from "../../utils/hooks/useSendMessage";
import { User } from "../slices/authSlice";
import { startTimeout } from "../actions/timeout";
import { selectConversationStartedAt } from "../selectors/idleSelector";
import { DateTime } from "luxon";
import IdleTimeoutWorker from "app/workers/IdleTimeoutWorker";
import { eventChannel } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import { PartnerSetting } from "../../utils/partnerSettings/defaults";

let tWorker = new IdleTimeoutWorker();

const getWorkerEventChannel = () => {
  return eventChannel<PayloadAction>((dispatch) => {
    tWorker.addEventListener(dispatch);
    return () => {
      tWorker.removeEventListener(dispatch);
    };
  });
};

const forkWorkerChannelHandler = () => {
  const channel = getWorkerEventChannel();

  return takeEvery(channel, function* dispatchAction(action) {
    yield put(action);
  });
};

function* runWorker() {
  yield forkWorkerChannelHandler();
}

function* idleSoonSideEffectHandler() {
  yield takeLatest(idleSoon, function* () {
    const setting: PartnerSetting = yield select(selectPartnerSettings);
    const user: User = yield select(selectUser);
    const idleSoFarMinutes = setting.timeouts.timeoutReminder / 60;
    const timeLeftToTimeoutMinutes =
      (setting.timeouts.timeToTimeout - setting.timeouts.timeoutReminder) / 60;

    if (setting.idleMessageStyle === "inline") {
      const message = generateMessage(
        generateIdleMessageText(timeLeftToTimeoutMinutes),
        generateIdleMessageTitle(idleSoFarMinutes),
        user,
        "yellow"
      );
      yield put(addMessage(message));
    }
  });
}

function* idleSideEffectHandler() {
  yield takeEvery(idle, function* () {
    Analytics.log(
      "Passive action: system closed connection due to idle timeout"
    );
    // const isIframeUI: boolean = yield select(selectIsIframeUI);
    const setting: PartnerSetting = yield select(selectPartnerSettings);

    // @todo disccuss with maadie should we close the connection or send the idle_timeout and force the conversation to close.
    // if (isIframeUI) {
    if (setting.idleMessageStyle === "inline") {
      yield put(
        sendMessage({ message: "IDLE_TIMEOUT", systemGenerated: true })
      );
    } else {
      yield put(
        closeConnection({ code: SocketCloseCodes.CLOSE_APP_IDLE_TIMEOUT })
      );
    }
  });
}

/**
 * cancel timers if conversation get closed
 */
function* conversationGotClosed() {
  yield takeEvery(closeConversation, function () {
    tWorker.send({
      action: closeConversation.toString(),
    });
  });
}

function* conversationGotActivated() {
  yield takeLatest(active, function* () {
    const settings: PartnerSetting = yield select(selectPartnerSettings);

    let timeoutReminder = settings.timeouts.timeoutReminder;
    let timeToTimeout = settings.timeouts.timeToTimeout;

    const assigned: DateTime | null = yield select(selectConversationStartedAt);

    if (assigned) {
      tWorker.send({
        action: active.toString(),
        reminderInSec: timeoutReminder,
        idleInSec: timeToTimeout,
      });
    }
    yield conversationGotClosed();
  });
}

/**
 * if we received conversationStartedFromBackend we set the conversation to active
 */
function* conversationGotAssignedHandler() {
  yield takeLatest(startTimeout, function* () {
    yield put(active());
  });
  yield takeLatest(onOpen, function* (action) {
    const { reconnecting } = action.payload;
    if (!reconnecting) {
      yield put(active());
      Analytics.log("Passive Action: Socket connected");
    } else {
      Analytics.log("Passive Action: Socket Reconnecting");
    }
  });
}

export default combineSagas([
  conversationGotAssignedHandler,
  conversationGotActivated,
  idleSoonSideEffectHandler,
  idleSideEffectHandler,
  runWorker,
]);
