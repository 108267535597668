import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  breathingOrb: {
    height: "5em",
    width: "5em",
    borderRadius: "200px",
    background: `${theme.palette.grey[700]}`,
    margin: "auto",
    marginTop: "200px",
  },
  orb: {
    animationName: "$orb",
    animationDuration: "5s",
    animationDelay: "0s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    animationDirection: "alternate",
  },
  loading: {
    margin: "37% 5% 2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    color: "grey",
  },
  loadBtn: {
    margin: "100px",
  },

  "@keyframes orb": {
    from: {
      transform: "scale(1)",
      background: `${theme.palette.grey[700]}`,
    },
    to: {
      transform: "scale(3)",
      background: `${theme.palette.grey[700]}`,
    },
  },
}));
