import { makeStyles, Theme } from "@material-ui/core/styles";

type messageType = (theme: Theme) => {
  border: any;
  boxSizing: any;
  color: any;
  maxWidth: any;
  overflowWrap: any;
  padding: any;
};
const message: messageType = (theme) => {
  return {
    border: "1px solid #919191",
    boxSizing: "border-box",
    color: `${theme.palette.grey[600]}`,
    maxWidth: "323px",
    overflowWrap: "anywhere",
    padding: theme.spacing(1, 2),
  };
};

export default makeStyles((theme) => ({
  message: {
    ...message(theme),
  },
  myMessage: {
    ...message(theme),
    borderRadius: "14px 2px 14px 14px",
    background: `${theme.palette.common.white}`,
    marginLeft: "auto",
  },
  theirMessage: {
    ...message(theme),
    borderRadius: "2px 14px 14px 14px",
    background: `${theme.palette.ctlColors.white}`,
    marginRight: "auto",
    border: "1px solid #e7e7e7",
  },
  myMessage988: {
    ...message(theme),
    borderRadius: "14px 14px 2px 14px",
    border: "none",
    background: `${theme.palette.colors988.green}`,
    color: `${theme.palette.common.white}`,
    marginLeft: "auto",
  },
  theirMessage988: {
    ...message(theme),
    borderRadius: "14px 14px 14px 2px",
    background: `${theme.palette.colors988.grey}`,
    marginRight: "auto",
    border: "1px solid #e7e7e7",
    maxWidth: "80%",
  },
  myTime: {
    padding: theme.spacing(1),
    marginLeft: "auto",
  },
  theirTime: {
    padding: theme.spacing(0.5),
    marginRight: "auto",
  },
  thread: {
    overflowY: "auto",
    flexGrow: 1,
    maxHeight: "75vh",
  },
  time: {
    fontFamily: "Public Sans, serif",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "1rem",
    lineHeight: "1.188rem",
    color: theme.palette.grey[500],
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  systemMessageContainer: {
    background: `${theme.palette.colors988.lightBlue}`,
  },
  systemMessage: {
    textAlign: "left",
  },
  systemMessageTitle: {
    textAlign: "left",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      marginTop: "3rem",
    },
  },
  systemMessageTime: {
    padding: "1em",
  },
  systemMessageLink: {
    color: `${[theme.palette.ctlColors.darkGrey]}`,
    display: "inline",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "1.05rem",
    padding: `0px 0px 0px 2px`,
    textAlign: "left",
  },
}));
