import theme from "app/utils/theme";
import soundOnImage from "pages/soundOn.svg";
import soundOffImage from "pages/soundOff.svg";
import Switch from "@material-ui/core/Switch";
import { styled } from "@material-ui/core/styles";

const SoundSwitch = styled(Switch)(({ theme: Theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${soundOnImage})`,
        backgroundColor: theme.palette.ctlColors.darkGrey,
        borderRadius: "50%",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.grey[400],
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.ctlColors.darkGrey,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${soundOffImage})`,
      backgroundColor: "#757575",
      borderRadius: "50%",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 20 / 2,
  },
}));

export default SoundSwitch;
