import React from "react";
import { Container, Button } from "@material-ui/core";
import illustrationOfPeople from "pages/illustrationOfPeople.svg";
import illustrationOfMessageWithPrivacyLock from "pages/illustrationOfMessageWithPrivacyLock.svg";
import illustrationOfPhones from "pages/illustrationOfPhones.svg";
import useStyles from "./styles";
import InfoCard from "./InfoCard";
import OpenInNewTabIconHelper from "../StyledMaterialUI/OpenInNewTabHelper";

function MoreInfo() {
  const classes = useStyles();

  return (
    <Container className={classes.moreInfoContainer}>
      <Container
        maxWidth={"xl"}
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <InfoCard title="Who is this for?" illustration={illustrationOfPeople}>
          Crisis Text Line is here for anyone, in any type of crisis. Message us
          for free, 24/7 support in English from anywhere in the US. We’re here
          to listen to anything you need to get off your chest. We’re here for
          you.
        </InfoCard>

        <InfoCard
          title="Is This Private?"
          illustration={illustrationOfMessageWithPrivacyLock}
        >
          Yes. Privacy and trust are important to us. In general, anything you
          share with us stays with us. In rare cases, we may need to share
          information to protect your safety, protect the safety of others, or
          comply with the law. For further details on how we collect, store, and
          share data, please review our{" "}
          <Button
            role="link"
            variant="text"
            color="secondary"
            href="https://www.crisistextline.org/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.termsAndConditionsText}
            aria-label="Terms of Service and Privacy Policy (opens in a new tab)"
            style={{ backgroundColor: "transparent" }}
          >
            Terms of Service & Privacy{" "}
            <span className={classes.whiteSpace}>
              {" "}
              Policy.
              <OpenInNewTabIconHelper />
            </span>
          </Button>
        </InfoCard>

        <InfoCard title="How Does It Work?" illustration={illustrationOfPhones}>
          When you message Crisis Text Line, we aim to connect you to a trained
          volunteer Crisis Counselor in less than five minutes. The goal is to
          help people move from a moment of crisis to calm and centered, guiding
          them to create a plan to stay safe and healthy.
        </InfoCard>
      </Container>
    </Container>
  );
}

export default MoreInfo;
