const navigation = {
  splash: "Navigation: Splash Screen",
  introNew: "Navigation: Start New Conversation",
  introStarted: "Navigation: Continue Conversation",
  chatOngoing: "Navigation: Chat Started",
  chatClosed: "Navigation: Chat Closed",
  pageGoingAway: "Page Visibility: Invisible",
  pageComingBack: "Page Visibility: Visible",
};

const errorPages = {
  openedInAnotherTab: "Error: Opened In Another Tab",
};

const userActions = {
  talkToACounselor: "User Action: Talk To A Counselor",
  confirmNewTab: "User Action: Confirm New Tab",
  sendMessage: "User Action: Send Message",
  clickLink: "User Action: Click link",
};

const popups = {
  idleTimeout: "Popup: Idle Timeout",
  closeConversation: "Popup: Close Conversation",
  mobileWarning: "Popup: Mobile Warning",
};

export const events = {
  navigation,
  errorPages,
  userActions,
  popups,
  error: "Error: ",
};
