import { createTransform } from "redux-persist";
import { PARTNER_GOOGLE } from "../authSlice";

const AuthTransform = createTransform(
  (inboundState, key) => inboundState,
  // transform state being rehydrated
  (outboundState, key) => {
    return {
      // @ts-ignore TS2571: Object is of type 'unknown'
      ...outboundState,
      // @ts-ignore TS2571: Object is of type 'unknown'.
      partner: outboundState.partner || PARTNER_GOOGLE,
    };
  },
  { whitelist: ["user"] }
);

export default AuthTransform;
