import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog } from "@material-ui/core";

const RoundedDialog = withStyles({
  paper: {
    border: `1px solid #919191`,
    borderRadius: "14px !important",
    boxShadow: "none !important",
  },
  container: {
    backgroundColor: "rgba(0,0,0,.3)",
  },
})(Dialog);

export default RoundedDialog;
