import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import RoundedDialog from "components/StyledMaterialUI/RoundedDialog";
import Transition from "./Transition";
import theme from "app/utils/theme";
import useStyle from "./styles";
import { useAnalyticsLogWithCondition } from "app/utils/hooks/useAnalyticsLog";
import ClearIcon from "@material-ui/icons/Clear";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import { useSelector } from "react-redux";
import { events } from "../../app/utils/analytics/events";

type CloseConversationDialogProps = {
  isOpen: boolean;
  onAccept: () => void;
  onClose: () => void;
};

type CloseDialogProps = {
  onAccept: () => void;
  onClose: () => void;
};

const DialogCTL = ({ onAccept, onClose }: CloseDialogProps) => {
  const classes = useStyle();
  return (
    <DialogContent className={classes.endConvoDialog}>
      <div style={{ justifyContent: "space-evenly" }}>
        <Typography variant="body1" className={classes.endConvoDescription}>
          Your Crisis Counselor will work with you to end your conversation.
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ display: "block" }}
            className={classes.continueConvo}
          >
            Message my Crisis Counselor
          </Button>
          <div className={classes.endConvoBubble}>
            <Button
              color="primary"
              onClick={onAccept}
              style={{ backgroundColor: "transparent" }}
              className={classes.endConvoButton}
            >
              End my conversation immediately
            </Button>
            <Typography variant="body1">
              Your conversation contents will be cleared after 10 minutes.
            </Typography>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

const CompactDialog = ({ onAccept, onClose }: CloseDialogProps) => {
  const classes = useStyle();
  const settings = useSelector(selectPartnerSettings);

  return (
    <DialogContent className={classes.alternateEndConvoDialog}>
      <Button
        variant="contained"
        style={{ backgroundColor: "transparent" }}
        onClick={onClose}
      >
        {settings.ui.cancelButtonText}
      </Button>
      <Button
        variant="contained"
        onClick={onAccept}
        style={{
          backgroundColor: theme.palette.colors988.blue,
          color: theme.palette.ctlColors.white,
          fontWeight: "800",
        }}
      >
        {settings.ui.okButtonText}
      </Button>
    </DialogContent>
  );
};

export default function CloseConversationDialog({
  isOpen,
  onAccept,
  onClose,
}: CloseConversationDialogProps) {
  const classes = useStyle();
  const settings = useSelector(selectPartnerSettings);

  useAnalyticsLogWithCondition(events.popups.closeConversation, isOpen);

  return (
    <RoundedDialog
      open={isOpen}
      TransitionComponent={Transition}
      maxWidth={"md"}
      style={{ textAlign: "center" }}
      onClose={onClose}
    >
      <div>
        <IconButton
          aria-label="Close"
          onClick={onClose}
          className={classes.closeEndConvoDialogueButton}
        >
          <ClearIcon />
        </IconButton>
      </div>
      <DialogTitle disableTypography className={classes.endConvoTitle}>
        {settings.stopButtonVariant === "simple"
          ? settings.ui.closeConversationDialog.leaveChatCompact
          : settings.ui.closeConversationDialog.leaveChat}
      </DialogTitle>
      {settings.stopButtonVariant === "simple" ? (
        <CompactDialog onAccept={onAccept} onClose={onClose} />
      ) : (
        <DialogCTL onAccept={onAccept} onClose={onClose} />
      )}
    </RoundedDialog>
  );
}
