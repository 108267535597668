import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import RoundedDialog from "components/StyledMaterialUI/RoundedDialog";
import React from "react";
import useStyle from "./styles";
import { useAnalyticsLogWithCondition } from "app/utils/hooks/useAnalyticsLog";
import mobileWarning from "./img/mobileWarning.svg";
import Transition from "./Transition";
import { events } from "../../app/utils/analytics/events";

type MobileWarningProps = {
  isOpen: boolean;
  onAccept: () => void;
};

export default function MobileWarning({
  isOpen,
  onAccept,
}: MobileWarningProps) {
  const classes = useStyle();

  useAnalyticsLogWithCondition(events.popups.mobileWarning, isOpen);

  return (
    <RoundedDialog
      open={isOpen}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      style={{ textAlign: "center" }}
      onClose={onAccept}
    >
      <div className={classes.warningImg}>
        <img src={mobileWarning} alt="phone with misaligned content example" />
      </div>
      <DialogTitle disableTypography className={classes.mobileEndConvoTitle}>
        This website may not work well on your phone.
      </DialogTitle>
      <DialogContent className={classes.warningDialog}>
        <Typography variant={"body2"} className={classes.clearPadding}>
          For a better experience, text STUDENT to 741741 or...
        </Typography>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onAccept}
            aria-label={"Continue here"}
          >
            Continue here
          </Button>
        </Grid>
      </DialogContent>
    </RoundedDialog>
  );
}
