import { secToMs } from "../converter";
import type { ErrorKeys } from "../../../components/Errors/ErrorContainer";

export type StopButtonVariants = "simple" | "complex";
export type IdleMessageStyle = "inline" | "popup";
export type startMessageTime = "inline" | "down" | "none";
export type PartnerSetting = {
  misc: {
    keyword: string;
    phoneNumber: string;
    customHomepageRoute?: string;
    whatsAppNumber?: string;
  };
  timeouts: {
    timeoutReminder: number;
    timeToTimeout: number;
    failMessageAfter: number;
    timeToClearConversationAfterClose: number;
    clearConversationAfterClose: boolean;
  };
  stopButtonVariant: StopButtonVariants;
  idleMessageStyle: IdleMessageStyle;
  // This message is to reduce confusion about getting disconnected when on a
  // mobile device and navigating to a different app
  disconnectDisclaimer: string;
  startMessage: {
    showStartMessageText: boolean;
    startMessageText: string;
    startMessageTitle?: string;
    showTime: startMessageTime;
  };
  autoStartConversation: boolean;
  showMenu: boolean;
  tracking: {
    trackWindowClosed: boolean;
  };
  ui: {
    stopButtonText: { [K in StopButtonVariants]: string };
    cancelButtonText: string;
    okButtonText: string;
    chatboxLabelText: string;
    chatboxTooShortPreText: string;
    chatboxTooShortPostText: string;
    chatboxTooLongPreText: string;
    chatboxTooLongPostText: string;
    closeConvoPreText: string;
    closeConvoPostText: string;
    sendMessageButtonText: string;
    sendMessageButtonColor: "secondary" | "primary";
    closeConversationDialog: {
      leaveChat: string;
      leaveChatCompact: string;
    };
    yourConversationHasBeenClosed: string;
  };
  staticPages: {
    [key in ErrorKeys]: {
      description: string;
    };
  };
};

export const DEFAULT_TIMEOUT = 60;
export const DEFAULT_TIMEOUT_REMINDER = 55;
export const DEFAULT_FAIL_MESSAGE_AFTER = secToMs(10);

export const staticPageDefaultContent = {
  generic: {
    description:
      "Include a description of the error and clear action the user may take to solve it (or a workaround).",
  },
  noToken: {
    description:
      "Webchat support is not available through your link. You are brave to reach out, and we're still here for you. Please check your internet connection and try again. If that doesn't work, text STUDENT to 741741.",
  },
  disconnected: {
    description: "You have been disconnected. Please get another link.",
  },
  cannotConnect: {
    description:
      "Webchat support is not available through your link. You are brave to reach out, and we're still here for you. Please check your internet connection and try again. If that doesn't work, text STUDENT to 741741.",
  },
  openedInAnotherTab: {
    description:
      "Want to chat here instead? \n" +
      "Refresh this page to continue your conversation.",
  },
  conversationCleared: {
    description:
      "You have been disconnected and conversation is cleared. Please get another link.",
  },
  notFound: {
    description: "We can't find what you're looking for.",
  },
};

export const cannotConnectDescCTL =
  "Webchat support is not available. Please check your internet connection and try again. If that doesn’t work, you can <a href='sms:741741?&body=HOME'>text HOME to 741741</a> or reach us via <a href='https://wa.me/14437877678?text=HOME&lang=en'>WhatsApp.</a>";
