import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import useStyles from "../Intro/styles";

const OpenInNewTabIconHelper = () => {
  const classes = useStyles();

  return (
    <OpenInNewIcon
      className={classes.termsAndConditionsIcon}
      aria-hidden="true"
      focusable="false"
    />
  );
};

export default OpenInNewTabIconHelper;
