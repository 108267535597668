import {
  messageReceived,
  updateMessageStatus,
  closeConversation,
} from "app/redux/slices/conversationSlice";
import { combineSagas, on } from "app/utils/sagaUtils";
import {
  closeConnection,
  updateConversationStatus,
} from "app/redux/actions/sockets";
import { startTimeout } from "app/redux/actions/timeout";

function* handleIncoming() {
  yield on({
    sendMessage: messageReceived,
    updateStatus: updateMessageStatus,
    closeConversation: closeConversation,
    updateConversationStatus: updateConversationStatus,
    closeConnection: closeConnection,
    conversationAssigned: startTimeout,
  });
}

export default combineSagas([handleIncoming]);
