import {
  delay,
  put,
  takeEvery,
  cancel,
  fork,
  select,
} from "redux-saga/effects";
import type { Task } from "redux-saga";
import { combineSagas, on } from "app/utils/sagaUtils";
import { delayFailMessage } from "app/redux/actions/conversation";
import { updateMessageStatus } from "app/redux/slices/conversationSlice";
import type { Settings } from "app/utils/settings";
import type { UpdateStatusData } from "app/sharedTypes/socketActionTypes";
import { SagaEffect } from "app/sharedTypes/SagaTypeHelper";
import { selectPartnerSettings } from "../selectors/conversationSelector";

// an effect that can be canceled;
function* doFail(messageId: string, ms: number) {
  yield delay(ms);
  yield put(
    updateMessageStatus({
      messageId: messageId,
      status: "failed",
      errors: ["no server confirmation received"],
    })
  );
}

function* handleDelayFailMessage() {
  const delayedFailMessage: {
    [messageId in string]: Task;
  } = {};
  const settings: Settings = yield select(selectPartnerSettings);
  yield takeEvery(delayFailMessage, function* (message): SagaEffect<Task> {
    const { messageId, action } = message.payload;

    switch (action) {
      case "add": {
        delayedFailMessage[messageId] = yield fork(
          doFail,
          messageId,
          settings.timeouts.failMessageAfter
        );
        break;
      }
      case "cancel": {
        yield cancel(delayedFailMessage[messageId]);
        delete delayedFailMessage[messageId];
        break;
      }
    }
  });
}

function* handleUpdateStatus() {
  yield on({
    updateStatus: ({ messageId }: UpdateStatusData) => {
      return delayFailMessage({ messageId: messageId, action: "cancel" });
    },
  });
}

export default combineSagas([handleDelayFailMessage, handleUpdateStatus]);
