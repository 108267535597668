import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import {
  delayFailMessage,
  emitSocketAction,
  retrySendMessage,
  sendMessage,
} from "app/redux/actions/conversation";
import { DateTime } from "luxon";
import { nanoid } from "@reduxjs/toolkit";
import {
  selectFirstMessagesByContent,
  selectPartnerSettings,
  selectUser,
} from "app/redux/selectors/conversationSelector";
import {
  addMessage,
  Message,
  updateMessageStatus,
} from "app/redux/slices/conversationSlice";
import type { User } from "app/redux/slices/authSlice";
import { combineSagas } from "app/utils/sagaUtils";
import { OutgoingSocketEventDataMap } from "app/sharedTypes/socketActionTypes";
import { active } from "app/redux/slices/IdleSlice";
import { onOpen } from "app/redux/actions/sockets";
import type { Settings } from "app/utils/settings";

// this function checks if we did send a message just now
const isMessageSentFromMoreThan4SecAgo = ({ status, sendAt }: Message) =>
  status === "sent" && DateTime.local().toSeconds() - sendAt > 4;

function* handleOutgoing() {
  yield takeEvery(sendMessage, function* (messageAction) {
    const user: User = yield select(selectUser);
    const { message, systemGenerated = false } = messageAction.payload;

    const messagePayload = {
      messageId: nanoid(16),
      body: message,
      identity: user.identity,
      lang: user.lang,
      sendAt: DateTime.local().toSeconds(),
    };
    yield put(
      addMessage({
        ...messagePayload,
        isMine: true,
        systemGenerated,
      })
    );
    yield call(emitMessageWithDelayedFailed, messagePayload);
    if (!systemGenerated) {
      yield put(active());
    }
  });
}

function* emitMessageWithDelayedFailed(
  messagePayload: OutgoingSocketEventDataMap["sendMessage"]
) {
  yield put(emitSocketAction({ action: "sendMessage", data: messagePayload }));

  yield put(
    delayFailMessage({ messageId: messagePayload.messageId, action: "add" })
  );
}

function* handleRetrySendMessage() {
  yield takeEvery(retrySendMessage, function* (messageAction) {
    const {
      systemGenerated,
      isMine,
      status,
      receiveConfirmedAt,
      ...messagePayload
    } = messageAction.payload;
    yield call(emitMessageWithDelayedFailed, {
      ...messagePayload,
    });
    yield put(
      updateMessageStatus({
        messageId: messagePayload.messageId,
        status: "sending",
      })
    );
  });
}

/**
 * update TypeScript and
 */
function* handleConversationStatus() {
  yield takeEvery(onOpen, function* () {
    yield delay(2000);
    const settings: Settings = yield select(selectPartnerSettings);
    const message: Message = yield select((state) =>
      selectFirstMessagesByContent(state, settings.misc.keyword)
    );

    if (message && isMessageSentFromMoreThan4SecAgo(message)) {
      yield put(
        emitSocketAction({ action: "getConversationStatus", data: {} })
      );
    }
  });
}

export default combineSagas([
  handleOutgoing,
  handleRetrySendMessage,
  handleConversationStatus,
]);
