import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  clearPadding: {
    padding: theme.spacing(2),
  },
  endConvoBubble: {
    background: "rgba(241, 61, 52, 0.08);",
    marginTop: "2rem",
    padding: "0.75rem 1.75rem 1.75rem",
    borderRadius: "9px",
    marginBottom: "16px",
    [theme.breakpoints.down(350)]: {
      padding: ".5rem",
    },
  },
  endConvoButton: {
    textDecoration: "underline",
    textUnderlineOffset: "1px",
    color: "#DB190F",
  },
  clearMyHistory: {
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    marginRight: "1px",
    [theme.breakpoints.down(361)]: {
      fontSize: ".816rem",
    },
  },
  clearMyHistoryText: {
    display: "inline-block",
    fontWeight: 600,
    color: `${[theme.palette.grey[500]]}`,
    [theme.breakpoints.down(361)]: {
      fontSize: ".816rem",
    },
  },
  endConvoTitle: {
    fontWeight: 300,
    fontSize: "1.375rem",
    lineHeight: "1.625rem",
    color: theme.palette.grey[600],
    padding: "0.7rem 2.5rem",
    textAlign: "center",
    marginTop: "32px",
    [theme.breakpoints.down(350)]: {
      padding: ".5rem 1rem",
    },
  },
  mobileEndConvoTitle: {
    fontWeight: 300,
    fontSize: "1.375rem",
    lineHeight: "1.625rem",
    color: theme.palette.grey[600],
  },
  endConvoDescription: {
    padding: ".5rem 2.35rem",
    [theme.breakpoints.down(350)]: {
      padding: ".5rem",
    },
  },
  endConvoDialog: {
    display: "flex",
    flexDirection: "column",
  },
  alternateEndConvoDialog: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "1rem",
    padding: "2rem",
  },
  warningDialog: {
    maxWidth: "420px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  endConvoImg: {
    width: "200px",
    height: "170px",
    alignSelf: "center",
  },
  warningImg: {
    alignSelf: "center",
    margin: theme.spacing(3),
  },
  closedConvoTextLink: {
    display: "inline",
    fontWeight: 300,
    lineHeight: "1.188rem",
    color: theme.palette.grey[600],
    "&:hover": {
      textDecoration: "none",
    },
  },
  closedConvoText: {
    display: "inline",
  },
  continueConvo: {
    maxWidth: "17.188rem",
    marginTop: "1rem",
  },
  closeEndConvoDialogueButton: {
    position: "absolute",
    right: theme.spacing.length / 2,
    top: theme.spacing.length / 2,
    color: "#444444",
  },
}));
