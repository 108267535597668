import theme from "app/utils/theme";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const GreyTooltip = withStyles({
  tooltip: {
    backgroundColor: `${theme.palette.ctlColors.darkGrey}`,
    color: "#ffffff",
    fontSize: "1em",
    fontWeight: 600,
    height: "auto",
    lineHeight: "1.188rem",
    width: "18.688rem",
    padding: "16px",
    borderRadius: "8px",
    [theme.breakpoints.between(0, 300)]: {
      width: "12.5rem",
      height: "auto",
    },
  },
  arrow: {
    color: `${theme.palette.ctlColors.darkGrey}`,
  },
})(Tooltip);

export default GreyTooltip;
