import React, { useRef, useState } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import MainContainer from "components/Layout/MainContainer";
import useStyles from "./styles";
import { useAnalyticsLog } from "app/utils/hooks/useAnalyticsLog";
import Footer from "../Layout/Footer";
import Menu from "../Layout/Menu";
import useChangeTitle from "app/utils/hooks/useChangeTitle";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { getPartnerToken } from "../../app/utils/AuthRemote";
import generalSettings from "../../app/utils/settings";
import { events } from "../../app/utils/analytics/events";
import { useSelector } from "react-redux";
import { selectPartnerSettings } from "app/redux/selectors/conversationSelector";
import AcceptTerms from "./AcceptTerms";

function NewConversation() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const classes = useStyles();
  const navigate = useNavigate();
  useAnalyticsLog(events.navigation.introNew);
  useChangeTitle(
    "Connect with a Crisis Text Line crisis counselor via an online text conversation"
  );
  const partnerSettings = useSelector(selectPartnerSettings);

  const handleStartConversation = async () => {
    setLoading(true);

    if (!recaptchaRef.current) {
      setError("Recaptcha not loaded");
      setLoading(false);
    } else {
      const recaptcha = recaptchaRef.current;
      const recaptchaPrevToken = recaptcha.getValue();
      if (recaptchaPrevToken !== "") {
        recaptcha.reset();
      }
      const recaptchaToken = await recaptcha.executeAsync();
      if (!recaptchaToken) {
        return false;
      }

      getPartnerToken(recaptchaToken)
        .then((obj) => {
          // redirect to auth route with the token.
          const token = obj.url.substring(obj.url.lastIndexOf("/") + 1);
          navigate(`/${token}`);
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <MainContainer header type={"intro"} containerShowMenu={false}>
        <section className={classes.about} style={{ justifyContent: "center" }}>
          <div className={classes.newConvoInfo}>
            <div>
              <Typography variant="h1" className={classes.welcomeText}>
                {generalSettings.welcomeText}
              </Typography>
            </div>
            <div
              style={{
                paddingRight: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartConversation}
              >
                {loading && <CircularProgress size={26} color="secondary" />}
                {!loading && generalSettings.letsChatButtonText}
              </Button>
              {error && (
                <Typography color={"error"} style={{ padding: "8px" }}>
                  {error}
                </Typography>
              )}
              {generalSettings.acceptTerms !== undefined && (
                <AcceptTerms {...generalSettings.acceptTerms} />
              )}
              {partnerSettings.disconnectDisclaimer !== "" && (
                <Typography className={classes.disconnectDisclaimerText}>
                  {partnerSettings.disconnectDisclaimer}
                </Typography>
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                className={classes.recaptchaContainer}
                sitekey={generalSettings.recaptchaPublicKey}
                size="invisible"
              />
            </div>
          </div>
        </section>
        <Menu />
        <Footer />
      </MainContainer>
    </>
  );
}

export default NewConversation;
