import { useEffect } from "react";
import Analytics from "app/utils/analytics";

/**
 * React Hook that store the data only once.
 * @param event
 * @param extraData
 */
export const useAnalyticsLog = (event: string, extraData: object = {}) => {
  useEffect(() => {
    Analytics.log(event, extraData);
    // eslint-disable-next-line
  }, []);
};
/**
 * React Hook to log data conditionally
 * @param event
 * @param shouldLogEvent
 * @param extraData
 */
export const useAnalyticsLogWithCondition = (
  event: string,
  shouldLogEvent: boolean,
  extraData: object = {}
) => {
  useEffect(() => {
    if (shouldLogEvent) {
      Analytics.log(event, extraData);
    }
    // eslint-disable-next-line
  }, [shouldLogEvent]);
};
